import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import CrossSelect from '../../assets/cross-select.svg';
import Delete from "../../assets/delete.svg";
import { Grid, Typography, Button, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function UploadDocumentPreview({
    handleClose,
    open,
    file,
    onSubmit,
    loader,
}) {
    console.log(file, "file")
    const [theme, setTheme] = useState(localStorage.getItem("theme"));
    const style = {
        position: "absolute",
        top: "45%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "#120A3A",
        boxShadow: 24,
        textAlign: "center",
        borderRadius: "15px",
        p: 4,
        height: "auto !important"
        // overflowY: "auto"
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={
                    theme === "Dark" ? "dark-modal modal-popup" : "light-modal modal-popup"
                }
            >
                <Box sx={style} className="select-vendor-popup" >
                    <div className='select-assessment-heading'>
                        <Typography>
                            Upload Document
                        </Typography>
                        <img src={CrossSelect} onClick={handleClose} alt='icon' />
                    </div>
                    <div className='padding-form-assessment topspace-extend' style={{ marginTop: "0px", }}>
                        <Typography id="modal-modal-description" style={{ textAlign: "center", marginBottom: "0px" }}>
                            {/* <img src={Delete} alt="icon" /> */}
                            <div className="" >
                                Are you sure you want to upload <b>{file?.name} </b> document ?<br />
                                <br />
                                click on below button to see document preview:
                            </div>
                            <Button
                                variant="contained"
                                className="cancel-popups-btn"
                                sx={{marginTop: 4}}
                                onClick={() => {
                                    if (file) {
                                        let url = URL.createObjectURL(file)
                                        window.open(url, "_blank");
                                    }
                                }}>
                                Preview
                            </Button>
                        </Typography>
                    </div>

                    <Grid item xs={12} md={12} lg={12} className="center-vendor" sx={{ margin: "20px  0px !important" }}>
                        <Button
                            variant="contained"
                            className="cancel-popups-btn cancel-as-well"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <LoadingButton loading={loader}
                            loadingIndicator={<CircularProgress size={12} sx={{ marginRight: "85px", color: "white" }} />}
                            size="large"
                            type="submit"
                            onClick={() => onSubmit()}
                            variant="contained" className="cancel-popups-btn"
                        >
                            <span>Confirm</span>
                        </LoadingButton>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
