import { TextareaAutosize } from "@mui/base";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PreviewIcon from "@mui/icons-material/Preview";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Upload from "../assets/Vectorplus.svg";
import PostRequest from "../components/apiConnections/postRequest";
import Loader from "../Loader";
import "./css/questionnaire.css";
import ModalDialog from "./ModalDialog.js";
import "./css/viewAnswerPage.css";
import BackArrow from '../assets/back-arrow.svg';
import SendArrow from '../assets/top-arrow-btn.svg';
import eyeIcon from '../assets/eye-icon.svg';
import pdfIcon from '../assets/PDF-icon.svg';
import crossIcon from '../assets/cross-select.svg'
import {
  Box,
  Button,
  Checkbox,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import GetRequest from "../components/apiConnections/getRequest.js";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function QuestionnaireSubmitted({ theme }) {
  const [value, setValue] = React.useState(0);
  const data = useParams();
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showLoader, setshowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // const [assessmentData, setAssessmentData] = useState(window.localStorage.getItem("assessmentData"));
  const [questions, setQuestions] = useState([]);
  const [queData, setQueData] = useState(
    JSON.parse(window.localStorage.getItem("assessmentData"))
  );
  const [assessmentId, setAssessmentId] = useState(data.id);
  const [questionnaire, setQuestionnaire] = useState({});
  const [submitted, setSubmitted] = useState(0);
  const [answered, setAnswered] = useState(0);
  const [deadline, setDeadline] = useState("");
  const [checkDeadlineOver, setCheckDeadlineOver] = useState();
  const [selectCategory, setSelectCategory] = useState(0);
  const [answersToBeSubmit, setAnswersToBeSubmit] = useState();
  const [ques, setQues] = useState({});
  const [questionnaireSet, setQuestionnaireSet] = React.useState("");
  const [domain, setDomain] = useState("");

  useEffect(() => {
    getQuestionnaire();
  }, []);
  useEffect(() => {
    return () => {
      do_saved(0);
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleRemoveFile = async (index) => {
    let answersData = answersToBeSubmit;
    const category = questions[value]?.category;
    let fileToBeDelete = answersData[category][index]?.fileName;
    answersData[category][index].fileName = "";
    setAnswersToBeSubmit(answersData);
    // enqueueSnackbar("File Uploaded", { variant : 'success' });
    await do_saved(0);
    if (fileToBeDelete) deleteEvidence(fileToBeDelete)
    // get fileName and apply removal API 
  }

  const deleteEvidence = async (fileName) => {
    try {
      if (!fileName) return;
      const endPoint = process.env.REACT_APP_REMOVE_EVIDENCE_FILE;
      const removeEvidence = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
        { fileName },
        ""
      );
    } catch (err) {
      console.log("error while deleting evidence")
    }
  }

  const handleImageChange = async (e, index) => {
    e.preventDefault();
    let files = e.target.files[0];
    if (files) {
      let dataImage = new FormData();
      dataImage.append("evidence", e.target.files[0]);
      const checkImage = e.target.files[0];
      const fileExtension = checkImage.name.replace(/^.*\./, "");
      const fileSize = Math.round(checkImage.size / 1024);
      if (
        fileExtension === "png" ||
        fileExtension === "PNG" ||
        fileExtension === "jpg" ||
        fileExtension === "JPG" ||
        fileExtension === "JPEG" ||
        fileExtension === "jpeg" ||
        fileExtension === "pdf" ||
        fileExtension === "PDF"
      ) {
        if (fileSize <= 5000) {
          try {
            const refreshToken = await PostRequest(
              `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
              {},
              {},
              "refreshToken"
            );
            if (refreshToken) {
              const endPoint = process.env.REACT_APP_UPLOAD_EVIDENCE;
              const uploadPicture = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endPoint}`,
                dataImage,
                "ImageUpload"
              );
              if (uploadPicture && uploadPicture.status === 200) {
                if (uploadPicture.data.code === 200) {
                  // let answersData = answersToBeSubmit;
                  // const category = questions[value]?.category;
                  // answersData[category][index].fileName = uploadPicture.data.data.filename;

                  const fileNewName = uploadPicture.data.data.filename;
                  let answersData = answersToBeSubmit;
                  const category = questions[value]?.category;
                  if (answersData[category][index]?.fileName) {
                    deleteEvidence(answersData[category][index]?.fileName)
                  }
                  answersData[category][index].fileName = fileNewName;
                  setAnswersToBeSubmit(answersData);
                  // enqueueSnackbar("File Uploaded", { variant : 'success' });
                  setshowLoader(false);
                  do_saved(0);
                } else {
                  enqueueSnackbar("Error in upload file.", {
                    variant: "error",
                  });
                  setshowLoader(false);
                }
              } else {
                enqueueSnackbar("Error in upload file.", { variant: "error" });
                setshowLoader(false);
              }
            }
          } catch (e) {
            enqueueSnackbar("Error in upload image.", { variant: "error" });
            setshowLoader(false);
          }
        } else {
          enqueueSnackbar("Please upload less than 2 mb file.", {
            variant: "error",
          });
          setshowLoader(false);
        }
      }
    }
  };

  const readRadioButton = async (event, index) => {
    const ans = event.target.value;
    let answersData = answersToBeSubmit;
    const category = questions[value]?.category;
    answersData[category][index].ans = ans;
    setAnswersToBeSubmit(answersData);
    await auto_saved();
    if (answered === 0) {
      await getQuestionnaire();
    }
  };
  const questionnaireAnswerConfig = async (event, index) => {
    const ans = event.target.value;
    let answersData = answersToBeSubmit;
    const category = questions[value]?.category;
    if (answersData[category][index].ans == "")
      answersData[category][index].ans = [ans];
    else {
      let answerArr = [];
      if (answersData[category][index].ans?.includes(ans)) {
        answerArr = answersData[category][index].ans?.filter((d) => d != ans);
        answersData[category][index].ans = answerArr;
      } else {
        answersData[category][index].ans.push(ans);
      }
    }
    setAnswersToBeSubmit({ ...answersData });
    await auto_saved();
    if (answered === 0) {
      await getQuestionnaire();
    }
  };
  const readRemark = async (event, index) => {
    const ans = event.target.value;
    let answersData = answersToBeSubmit;
    const category = questions[value]?.category;
    answersData[category][index].remark = ans;
    setAnswersToBeSubmit(answersData);
    await auto_saved();
  };
  // const fileUpload = (event, index) => {
  //   const fileName = event.target.value;
  //   let answersData = answersToBeSubmit;
  //   const category = questions[value]?.category;
  //   answersData[category][index].fileName = fileName;
  //   setAnswersToBeSubmit(answersData);
  // };

  const auto_saved = async () => {
    let payloadQue = {
      ansObj: [
        {
          assessmentId: assessmentId,
          set: ques.set,
          questionnaires: answersToBeSubmit,
        },
      ],
      type: 0,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const resultData = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SAVE_QUESTIONNAIRE_WITH_ANSWER}`,
          payloadQue
        );
        if (resultData && resultData.status === 200) {
          // getQuestionnaire();
        }
      } else {
        setshowLoader(false);
        window.location.href = "/";
      }
    } catch (error) { }
  };
  // const getAiEvidence = () => {
  //   setshowLoader(true);

  //   const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_ASSESSMENT_EVIDENCE_MAPPING}?assessmentId=${assessmentId}&domain=${domain}`;

  //   GetRequest(url, {})
  //     .then((response) => {
  //       enqueueSnackbar(response.data.message, { variant: "success" });
  //       setshowLoader(false);
  //       // setData(response?.data?.data);
  //       // getQuestionnaire();
  //     })
  //     .catch((error) => {
  //       enqueueSnackbar(error.message, { variant: "error" });
  //       setshowLoader(false);
  //     });
  // };

  const do_saved = async (type) => {
    // alert("do saved")
    if (
      ques.set === "" ||
      ques.set === undefined ||
      answersToBeSubmit === "" ||
      answersToBeSubmit === null ||
      answersToBeSubmit === undefined
    ) {
      // enqueueSnackbar("Answer closed.", { variant: "error" });
      setshowLoader(false);
      return false;
    }

    if (type === 1) {
      console.log(ques.set)
      console.log(answersToBeSubmit)
      let checkIfAllAnswered = Object.values(answersToBeSubmit || {})?.flat()?.every((item) => item.ans)
      if (!checkIfAllAnswered) {
        enqueueSnackbar("Please answer all questions before submitting.", { variant: "error" });
        return;
      }
    }
    setshowLoader(true);
    let payloadQue = {
      ansObj: [
        {
          assessmentId: assessmentId,
          set: ques.set,
          questionnaires: answersToBeSubmit,
        },
      ],
      type: type,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const resultData = await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SAVE_QUESTIONNAIRE_WITH_ANSWER}`,
          payloadQue
        );
        // console.log("resultData" , resultData)

        if (resultData && resultData.status === 200) {
          enqueueSnackbar(resultData.data?.message, {
            variant: "success",
          });
          setshowLoader(false);
          getQuestionnaire();
          setTimeout(() => {
            if (type === 1) {
              // getAiEvidence() ; 
              updateInsideoutScore();
              navigate("/vendor/vendorAssessment", { replace: true });
            }
          }, 1000)
        } else {
          enqueueSnackbar("Answer not submitted.", { variant: "error" });
          setshowLoader(false);
        }
      } else {
        setshowLoader(false);
        window.location.href = "/";
      }
    } catch (error) {
      enqueueSnackbar("Answer not submitted.", { variant: "error" });
      setshowLoader(false);
    }
  };

  const getQuestionnaire = async () => {
    setshowLoader(true);
    const payload = {
      assessment_id: assessmentId,
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        const endpoint = process.env.REACT_APP_GET_QUESTIONNAIRE_BY_VENDOR;
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
          payload
        )
          .then((res) => {
            let response = res.data.data;
            setQuestions(response.data);
            setQues(response);
            setAnswered(response.answered);
            setSubmitted(response.submitted);
            setDomain(response.domain)
            let currentTime = Math.round(Date.now() / 1000);
            setCheckDeadlineOver(Math.sign(response.deadline - currentTime));
            let finalObject = {};
            if (parseInt(response.answered) === 0) {
              response.data.map((item, index) => {
                let ansarray = [];
                item.question.map((itemque, indexque) => {
                  let ans = "";
                  if (itemque.optionType == "MCQ") ans = [];
                  let objecttoAdd = {
                    que: itemque,
                    ans: ans,
                    remark: "",
                    fileName: "",
                  };
                  ansarray.push(objecttoAdd);
                  finalObject[item.category] = ansarray;
                });
                setAnswersToBeSubmit(finalObject);
              });
            } else {
              response.data.map((item, index) => {
                let ansarray = [];
                item.question.map((itemque, indexque) => {
                  ansarray.push(itemque);
                  finalObject[item.category] = ansarray;
                });
                setAnswersToBeSubmit(finalObject);
              });
            }
            // setTimeout(()=>{
            setshowLoader(false);
            // }, 1000)
          })
          .catch((err) => {
            enqueueSnackbar("Questionnaire details not find.", {
              variant: "error",
            });
            setshowLoader(false);
          });
      } else {
        window.location.href = "/";
      }
    } catch (error) {
      enqueueSnackbar("Questionnaire details not find.", { variant: "error" });
      setshowLoader(false);
    }
  };

  const ModalContent = () => {
    return "Save Assessment";
  };


  const updateInsideoutScore = async () => {
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      const payload = {
        "vendorDomain": domain,
        "assessmentId": assessmentId
      }

      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_UPDATE_INSIDEOUT_SCORE_ON_ASSESSMENT_SUBMISSION}`, payload)
          .then((res) => {
          })
          .catch((err) => {
            // enqueueSnackbar(err.message, { variant: 'error' });
          })
      } else {
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }


  return (
    <>
      <Loader show={showLoader} />
      <Container maxWidth={false} className="max-w-left">
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>

            <Typography variant="h4" sx={{ mb: 0 }}>
              <span className='backlink'>
                <img src={BackArrow} onClick={() => navigate("../vendorAssessment")} alt='icon' />
                Questionnaire
              </span>
              {checkDeadlineOver === -1 ? (
                <span style={{ color: "red" }}> (Target Date Has Expired!)</span>
              ) : null}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <div className="space-questionnaire">
              <div className="tabs-bg top-demo-space w-100-160">
                {/* <Link to={`../vendorAssessment`} style={{ float: "right" }}>
            <Button
              variant="contained"
              className="click-to-begin m-l-0 cancel-btn"
            >
              Back
            </Button>
          </Link> */}

                {questions?.map((element, index, array) => {
                  <Tab label={"test"} {...a11yProps(`tab11`)} />;
                })}
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}
                >
                  {questions?.map((tab, index) => (
                    <Tab
                      key={tab.category.toString() + index}
                      label={tab.category}
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              </div>
              <div className="tabs-bg top-demo-space w-100-cover">
                {questions?.map((tab, index) => (
                  <TabPanel value={value} index={index} className="tabpanel-full-size left-space-tab map-isssues-manage">
                    <div>
                      <div className="mapping-issue">{ques.set}</div>
                    </div>
                    <div className="question-part mt-questionpart padding-aling-view-answer">

                      {answered === 1
                        ? tab.question?.map((key, i) => (
                          <>
                            {key.que?.text ? (
                              <div>

                                <Grid item xs={12} md={10} lg={10} columns={0}>
                                  <div style={{ display: 'flex', marginTop: "15px" }}>
                                    <p className="preview-question-heading">Q. {i + 1}</p>
                                    <span className="data-text-preview">{key?.que?.text}</span>
                                  </div>
                                </Grid>
                                <div className="options-flex2">
                                  {/* MCQ  */}
                                  {key.que?.optionType == "MCQ" ? (
                                    <div>
                                      <FormControl>
                                        {key.que?.options?.map((option, j) => {
                                          return (
                                            <FormControlLabel
                                              style={{ fontSize: "14px" }}
                                              value={j + 1}
                                              control={
                                                <Checkbox
                                                  checked={key.ans?.includes(
                                                    `${j + 1}`
                                                  )}
                                                />
                                              }
                                              label={option.title}
                                              onChange={(e) =>
                                                questionnaireAnswerConfig(e, i)
                                              }
                                              disabled={
                                                submitted ||
                                                  checkDeadlineOver === -1
                                                  ? true
                                                  : false
                                              }
                                            />
                                          );
                                        })}
                                      </FormControl>
                                    </div>
                                  ) : (
                                    // SCQ
                                    <div>
                                      <FormControl>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="row-radio-buttons-group"
                                          defaultValue={key.ans}
                                        >
                                          {key.que?.options?.map((option, j) => {
                                            return (
                                              <FormControlLabel
                                                value={option.title}
                                                control={<Radio />}
                                                label={option.title}
                                                onChange={(e) =>
                                                  readRadioButton(e, i)
                                                }
                                                disabled={
                                                  submitted ||
                                                    checkDeadlineOver === -1
                                                    ? true
                                                    : false
                                                }
                                              />
                                            );
                                          })}
                                        </RadioGroup>
                                      </FormControl>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ) : (
                              <div>
                                <p>{key.que}</p>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    defaultValue={key.ans}
                                  >
                                    <FormControlLabel
                                      value="Yes"
                                      control={<Radio />}
                                      label="Yes"
                                      onChange={(e) => readRadioButton(e, i)}
                                      disabled={
                                        submitted || checkDeadlineOver === -1
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormControlLabel
                                      value="No"
                                      control={<Radio />}
                                      label="No"
                                      onChange={(e) => readRadioButton(e, i)}
                                      disabled={
                                        submitted || checkDeadlineOver === -1
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormControlLabel
                                      value="N/A"
                                      control={<Radio />}
                                      label="N/A"
                                      onChange={(e) => readRadioButton(e, i)}
                                      disabled={
                                        submitted || checkDeadlineOver === -1
                                          ? true
                                          : false
                                      }
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            )}

                            <p className="view-file attached-button">
                              <p style={{ marginLeft: "58px" }} className="writehere">Write here</p>
                              {!submitted ? (
                                key?.fileName !== "" &&
                                  key?.fileName !== undefined ? (
                                  <div style={{ display: "flex" }}>
                                    {/* <div>
                                      <img src={pdfIcon} alt="icon" />
                                    </div> */}
                                    <a
                                      href={
                                        process.env.REACT_APP_BACKEND_BASE_URL +
                                        "/evidence/" +
                                        key.fileName
                                      }
                                      target="_blank"
                                      className="view-evidence-button view-evidence-btn"
                                      style={{ display: "flex" }}
                                    >
                                      <img src={eyeIcon} alt="icon" />
                                    </a>
                                    <Button
                                      className="view-evidence-button view-evidence-btn"
                                      htmlFor={`file-upload${index + "_" + i}`}
                                      component="label"
                                    >
                                      Update
                                    </Button>
                                    <Button
                                      className="view-evidence-btn view-evidence-button"
                                      onClick={() => handleRemoveFile(i)}
                                    >
                                      {/* Remove */}
                                      <img src={crossIcon} alt="icon" />
                                    </Button>
                                  </div>
                                ) : checkDeadlineOver === -1 ? null : (
                                  <Button
                                    className="view-evidence-button"
                                    htmlFor={`file-upload${index + "_" + i}`}
                                    style={{
                                      color: "#ffffff",
                                    }}
                                    title="Select File"
                                    component="label"
                                  >
                                    <AttachFileIcon />
                                    Upload Attachment
                                  </Button>
                                )
                              ) : key?.fileName !== "" &&
                                key?.fileName !== undefined &&
                                key?.fileName ? (
                                <a
                                  href={
                                    process.env.REACT_APP_BACKEND_BASE_URL +
                                    "/evidence/" +
                                    key.fileName
                                  }
                                  target="_blank"
                                  className="set-view"
                                >
                                  <PreviewIcon />{" "}
                                </a>
                              ) : null}
                              <input
                                id={`file-upload${index + "_" + i}`}
                                name="fileName[]"
                                type="file"
                                accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG, application/pdf, application/PDF"
                                onChange={(e) => handleImageChange(e, i)}
                                style={{ display: "none" }}
                              />
                            </p>

                            <div className="flex-preview-tab2">
                              <TextareaAutosize
                                aria-label="Write here"
                                minRows={3}
                                className="w100-textarea"
                                defaultValue={key.remark || ""}
                                readOnly={submitted || checkDeadlineOver === -1}
                                onChange={(e) => readRemark(e, i)}
                              // style={{ marginLeft: "0%", maxWidth: "98%" }}
                              />
                            </div>
                          </>
                        ))
                        : tab.question?.map((key, i) => (
                          <>
                            {ques.setType == "custom" ||
                              ques.setType == "customAdmin" ? (
                              <div>
                                <p>{key.text}</p>
                                {key.optionType == "MCQ" ? (
                                  <div>
                                    <FormControl>
                                      {key.options?.map((option, j) => {
                                        return (
                                          <FormControlLabel
                                            value={j + 1}
                                            control={<Checkbox />}
                                            label={option.title}
                                            onChange={(e) =>
                                              questionnaireAnswerConfig(e, i)
                                            }
                                            disabled={
                                              submitted ||
                                                checkDeadlineOver === -1
                                                ? true
                                                : false
                                            }
                                          />
                                        );
                                      })}
                                    </FormControl>
                                  </div>
                                ) : (
                                  <div>
                                    <FormControl>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                      >
                                        {key.options?.map((option, j) => {
                                          return (
                                            <FormControlLabel
                                              value={option.title}
                                              control={<Radio />}
                                              label={option.title}
                                              onChange={(e) =>
                                                readRadioButton(e, i)
                                              }
                                              disabled={
                                                submitted ||
                                                  checkDeadlineOver === -1
                                                  ? true
                                                  : false
                                              }
                                            />
                                          );
                                        })}
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div>
                                <p>{key}</p>
                                <FormControl>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                  >
                                    <FormControlLabel
                                      value="Yes"
                                      control={<Radio />}
                                      label="Yes"
                                      onChange={(e) => readRadioButton(e, i)}
                                      disabled={
                                        submitted || checkDeadlineOver === -1
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormControlLabel
                                      value="No"
                                      control={<Radio />}
                                      label="No"
                                      onChange={(e) => readRadioButton(e, i)}
                                      disabled={
                                        submitted || checkDeadlineOver === -1
                                          ? true
                                          : false
                                      }
                                    />
                                    <FormControlLabel
                                      value="N/A"
                                      control={<Radio />}
                                      label="N/A"
                                      onChange={(e) => readRadioButton(e, i)}
                                      disabled={
                                        submitted || checkDeadlineOver === -1
                                          ? true
                                          : false
                                      }
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </div>
                            )}

                            <p className="view-file attached-button">
                              <p style={{ marginLeft: "58px" }} className="writehere">Write here</p>
                              {!submitted ? (
                                (key?.fileName !== "" &&
                                  key?.fileName !== undefined) ||
                                  (answersToBeSubmit[tab?.category][index]
                                    ?.fileName !== "" &&
                                    answersToBeSubmit[tab?.category][index]
                                      ?.fileName !== undefined) ? (
                                  <a
                                    href={
                                      process.env.REACT_APP_BACKEND_BASE_URL +
                                      "/evidence/" +
                                      key.fileName
                                    }
                                    target="_blank"
                                    className="set-view"
                                  >
                                    <PreviewIcon />
                                  </a>
                                ) : (
                                  <Button
                                    htmlFor={`file-upload${index + "_" + i}`}
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    component="label"
                                    className="pin-upload"
                                    title="Select File"
                                  >
                                    <img src={Upload} alt="icon" />{" "}
                                    <AttachFileIcon />
                                  </Button>
                                )
                              ) : (key?.fileName !== "" &&
                                key?.fileName !== undefined) ||
                                (answersToBeSubmit[tab?.category][index]
                                  ?.fileName !== "" &&
                                  answersToBeSubmit[tab?.category][index]
                                    ?.fileName !== undefined) ? (
                                <a
                                  href={
                                    process.env.REACT_APP_BACKEND_BASE_URL +
                                    "/evidence/" +
                                    key.fileName
                                  }
                                  target="_blank"
                                  className="set-view"
                                >
                                  <PreviewIcon />
                                </a>
                              ) : null}
                              {/* <div>
                               <Button htmlFor={`file-upload${index + '_' + i}`} size="small" variant="contained" color="primary" component="label" className="upload-new-plus" title="Select File">
                                <img src={Upload} alt="icon" /> <AttachFileIcon/>
                                </Button>
                              </div> */}
                              <input
                                id={`file-upload${index + "_" + i}`}
                                name="fileName[]"
                                type="file"
                                accept="image/jpg, image/png, image/PNG, image/jpeg, image/JPG, image/JPEG, image/pdf, image/PDF"
                                onChange={(e) => handleImageChange(e, i)}
                                style={{ display: "none" }}
                              />
                            </p>
                            <TextareaAutosize
                              aria-label="Write here"
                              minRows={3}
                              className="w100-textarea"
                              defaultValue={key.remark || ""}
                              readOnly={submitted}
                              onChange={(e) => readRemark(e, i)}
                            />
                          </>
                        ))}
                    </div>
                  </TabPanel>
                ))}

                <Grid item xs={12} md={12} lg={12}>
                  {!submitted && !(checkDeadlineOver === -1) ? (
                    <div className="move-margin-rigt-0">
                      <Button
                        variant="contained"
                        className="same-save-new"
                        onClick={() => do_saved(0)}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        className="same-save-new"
                        onClick={() => do_saved(1)}
                      >
                        <img src={SendArrow} className="send-arrow-icon" /> Submit
                      </Button>
                    </div>
                  ) : null}
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
        <ModalDialog
          handleClose={handleClose}
          open={open}
          onSubmit={() => do_saved(1)}
          contentData={ModalContent}
        />
      </Container>
    </>
  );
}
