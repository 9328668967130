import React, { useEffect, useState } from "react";
// @mui
import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";
import OtpInput from "react-otp-input";
// components
import { useSnackbar } from 'notistack';
import fullLight from '../../../assets/full-light.png';
import fullDark from "../../../assets/full-dark.png";
import PostRequest from "../../../components/apiConnections/postRequest";
import {
  getLocalStoarge,
  setLocalStoarge
} from "../../../components/common/allFunctions";
import Loader from "../../../Loader";
import { GetPermissionList } from '../../../utils/getPermissionList';
import { useDispatch, useSelector } from "react-redux";
import { addPermissionList } from '../../../redux/permissionListSlice';
import { Navigate, useNavigate } from 'react-router-dom';

export default function TwoFaForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const [otp, setOtp] = useState();

  // const [alertData, setAlertData] = useState({});
  const [showLoader, setshowLoader] = useState(false);

  useEffect(() => {
    // if (alertData.showalert) {
    //   setTimeout(() => {
    //     setAlertData({});
    //   }, 2000);
    // }
  }, []);

  const handleError = (data) => {
    console.log(data)
    enqueueSnackbar(data?.data?.data?.attemptLeft ?
      `Invalid OTP. Only ${data?.data?.data?.attemptLeft} attempt left` : data?.data?.message ?
        data?.data?.message : "Something went wrong", { variant: 'error' });
    return false;
  };
  const catchError = (error) => {
    enqueueSnackbar(error.message || 'Something went wrong.', { variant: 'error' });
    console.log(error.code, "error.code")
    if (error.code === 403) navigate("/")
    return false;
  };
  const do_verify = async (e) => {
    e.preventDefault();
    setshowLoader(true);
    try {
      const username = getLocalStoarge("userEmail");
      const payload = {
        username,
        otp,
      };

      const twofaResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_TWOFA_ENDPOINT}`,
        payload
      );
      if (twofaResult && twofaResult.status === 200) {
        if (twofaResult.data.code === 200) {
          const { accessToken, userTime, refreshToken, twofa, roleId, profilePic, userStatus, active, userId, isClientUser, companyDetails } = twofaResult.data.data;
          const logged_user = {
            accessToken,
            refreshToken,
            twofa,
            profilePic
          };
          setLocalStoarge("usertime", userTime);
          setLocalStoarge("roleId", roleId);
          setLocalStoarge("userId", userId);
          setLocalStoarge("c_detail", JSON.stringify(companyDetails))
          // setLocalStoarge("userEmail", email);    

          if (roleId === 1) {
            if (isClientUser) setLocalStoarge("icu", isClientUser)
            else setLocalStoarge("icu", 0)
            setLocalStoarge("R1", JSON.stringify(logged_user));
          } else if (roleId === 2) {
            setLocalStoarge("R2", JSON.stringify(logged_user));
            setLocalStoarge("icu", 0)
          } else if (roleId === 3) {
            setLocalStoarge("R3", JSON.stringify(logged_user));
            setLocalStoarge("icu", 0)
          } else if (roleId === 4) {
            setLocalStoarge("R4", JSON.stringify(logged_user));
            setLocalStoarge("icu", 0)
          }
          enqueueSnackbar('Logged in successfully.', { variant: 'success' });
          setshowLoader(false);
          setTimeout(async () => {
            if (roleId === 1) {
              if (isClientUser) {
                let permissionList = await GetPermissionList();

                if (!permissionList.error) dispatch(addPermissionList(permissionList.data))
                {
                  permissionList.data?.includes("Dashboard") ? window.location.href = "/dashboard/app"
                    : permissionList.data?.includes("Ecosystem") ? window.location.href = "/dashboard/vendors"
                      : permissionList.data?.includes("Attack Surface") ? window.location.href = "/dashboard/attacks"
                        : permissionList.data?.includes("Questionnaire") ? window.location.href = "/dashboard/questionnaire-library"
                          : permissionList.data?.includes("Assessment") ? window.location.href = "/dashboard/assessment"
                            : permissionList.data?.includes("Vulnerability") ? window.location.href = "/dashboard/vulnerability"
                              : permissionList.data?.includes("Chat") && navigate("/dashboard/clientChat");
                }
              } else window.location.href = "/dashboard/app"
            } else if (roleId === 2) {
              window.location.href = "/admin/addfile";
            } else if (roleId === 3) {
              if (active === 1) {
                window.location.href = "/vendor/vendorAssessment";
              }
              else {
                navigate("/vendor/change-password", { state: { forcePasswordGenerate: true } })
              }
            }
            else if (roleId === 4) {
              window.location.href = "third-party/dashboard";
            }
          }, 1000);
        } else {
          setshowLoader(false)
          handleError(twofaResult.data);

        }
      } else {
        setshowLoader(false)
        handleError(twofaResult);
      }


    } catch (error) {
      setshowLoader(false)
      catchError(error);
    }
  };

  const resendOTP = async () => {
    try {
      const username = getLocalStoarge("userEmail");
      const payload = {
        username
      };

      const otpRes = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_RESEND_OTP}`,
        payload
      );
      if (otpRes && otpRes.status === 200) {
        if (otpRes.data.code === 200) {
          enqueueSnackbar(otpRes.data.message, { variant: 'success' });
        } else {
          setshowLoader(false)
          handleError(otpRes.data);

        }
      } else {
        setshowLoader(false)
        handleError(otpRes);
      }


    } catch (error) {
      catchError(error);
    }
  }

  const handleChange = (otp) => setOtp(otp);
  return (
    <>
      <Loader show={showLoader} />
      <form onSubmit={(e) => do_verify(e)} className="fa-center">
        <Stack spacing={6}>
          <img src={fullDark} className="logo-size" alt="icon" />
          <Typography variant="h4" gutterBottom>
            <h2>2FA Verification</h2>
            <p>Enter verification code</p>
          </Typography>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={6}
            separator={<span> - </span>}
            inputStyle={{ width: "50px", height: "50px" }}
            className="input-style"
          />
          <div className="center-alignbtn">
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              className="login-buttton-gradient"
            >
              Confirm
            </LoadingButton>

          </div>
          <LoadingButton
            fullWidth
            size="large"
            type="button"
            variant="contained"
            className="login-buttton-gradient margin-twofa-top"
            onClick={resendOTP}
          >
            Resend
          </LoadingButton>
        </Stack>

      </form>

    </>
  );
}
