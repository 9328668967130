import React, { useEffect, useState } from 'react'
import SemiChart from "react-apexcharts";
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, Box, Stack, Typography, Tooltip, Button } from "@mui/material";
import GaugeComponent from 'react-gauge-component';
import DNS from '../../assets/dns-image.svg';
import Chart from '../../components/chart';
import Carousel from 'react-material-ui-carousel';
import CircularProgress from "./../CircularProgress.js";
import { useSnackbar } from 'notistack';
import colorsNew from "../../utils/colorcode.js";
import moment from 'moment';
import { Link } from 'react-router-dom';

function ClientOverviewHomeAdmin({ data, totalCritical, totalHigh, totalLow, totalMedium, complianceCardData, rows, totalScore, riskScore, score, reletionShipRisk }) {
    // console.log("data", data)
    const [logo, setLogo] = useState("/assets/images/avatars/user.png");
    const { enqueueSnackbar } = useSnackbar();
    const [domainName, setDomainName] = useState("");
    const [latestScanDate, setLatestScanDate] = useState();
    const [scoreLoader, setScoreLoader] = useState(true);
    const [scores, setScores] = useState({});
    const [overallRiskScore, setOverallRiskScore] = useState(0);
    const [relationship, setRelationship] = useState();
    const [insideScore, setInsideScore] = useState();
    const [outsideScore, setOutsideScore] = useState();


    useEffect(() => {
        setScoreLoader(true);
        if (score) {
            setScores(score);
            setLatestScanDate(moment(score?.latestScanDate).format('DD MMM YYYY'));
            setOverallRiskScore(score?.overallRiskScore);
            setInsideScore(score.insideScore*3);
            setOutsideScore(score.outsideScore*3);
            setScoreLoader(false);
        }
       
    }, [score])

    useEffect(() => {
        setLogo(
            data?.vendorData?.vendorInfo?.logo ||
            "/assets/images/avatars/user.png"
        );

        setRelationship(data?.vendorData?.relationshipRisk);
    }, [data]);

    useEffect(() => {
        if (overallRiskScore) {
            const displayValue = Math.round(overallRiskScore);
            const displayMax = 100;

            const valueTextElement = document.querySelector('.value-text text');
            if (valueTextElement) {
                valueTextElement.innerHTML = '';

                const scoreTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
                scoreTspan.textContent = displayValue;
                scoreTspan.setAttribute('style', 'font-size: 40px; font-weight: bold; fill: white;');

                const maxTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
                maxTspan.textContent = `/${displayMax}`;
                maxTspan.setAttribute('style', 'font-size: 20px; fill: white; margin-left: 5px;');

                valueTextElement.appendChild(scoreTspan);
                valueTextElement.appendChild(maxTspan);
            }
        }
    }, [overallRiskScore])

    // useEffect(() => {
    //   if (domainName) {
    //     // getChartData();
    //     // getVendorOverAllRiskScore();
    //   }
    // }, [domainName]);

    const chartSeries2 = [totalCritical || 0, totalHigh || 0, totalMedium || 0, totalLow || 0];
    /*Close first chart*/
    const getEmptyDonutOptions = (baseOptions) => ({
        ...baseOptions,
        colors: ['#292154', '#292154', '#292154', '#292154'], // Light color to show "empty" donut
        plotOptions: {
            pie: {
                donut: {
                    ...baseOptions.plotOptions.pie.donut,
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: 'No Data',
                            fontSize: '16px',
                            color: '#fff', // Muted color for "No Data" text
                            fontFamily: 'Mona-Sans, sans-serif',
                            style: {
                                fontFamily: 'Mona-Sans, sans-serif',
                                fontSize: '28px',
                                color: '#fff', // Muted color for total value
                            },
                            formatter: () => '0', // Show "0" for total when no data
                        },
                        value: {
                            show: true,
                            fontSize: '28x', // Font size for the total value
                            fontFamily: 'Mona-Sans, sans-serif',
                            color: '#fff', // Total value color
                            offsetY: 20 // Moved the total value upwards
                        }
                    },
                }
            }
        },
        tooltip: {
            enabled: false, // Disable tooltip on empty donut
        }
    });
    const chartOptions2 = {
        chart: {
            type: 'donut',
            width: "250",
            height: '250',
            dropShadow: {
                enabled: true,
                color: '#000', // Shadow color
                top: 2, // Vertical offset
                left: 0, // Horizontal offset
                blur: 3, // Blur radius
                opacity: 0.25 // Opacity of the shadow
            }
        },
        labels: [`Critical ${totalCritical || 0}`, `High ${totalHigh || 0}`, `Medium ${totalMedium || 0}`, `Low ${totalLow || 0}`],
        colors: [colorsNew.RED, colorsNew.ORANGE, colorsNew.YELLOW, colorsNew.GREEN,],
        stroke: {
            show: false, // Disable the outline/stroke
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            },
            breakpoint: 1200,
            options: {
                chart: {
                    width: 300
                },

            }
        }],
        plotOptions: {
            pie: {
                donut: {
                    size: '78%',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            offsetY: 30,  // Move the name (category) label down
                        },
                        value: {
                            show: true,
                            fontSize: '20px',
                            fontFamily: 'Mona-Sans, sans-serif',
                            color: '#fff',
                            offsetY: -15, // Move the value label up
                        },
                        total: {
                            show: true,
                            label: 'Total',
                            fontSize: '18px',
                            color: '#fff',
                            fontFamily: 'Mona-Sans, sans-serif',
                            formatter: () => totalCritical + totalMedium + totalHigh + totalLow,
                            style: {
                                fontFamily: 'Mona-Sans, sans-serif',
                                fontSize: '28px',
                                color: '#fff',
                            },
                            offsetY: 50, // Move total label down
                        },
                    }
                }
            }
        },
        dataLabels: {
            enabled: false,
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: '13px',
                fontFamily: 'Mona-Sans, sans-serif',
                colors: ['#fff']
            }
        },
        legend: {
            position: 'right',
            offsetY: 20,
            offsetX: 10,
            height: 150,
            markers: {
                width: 11,           // Width of the square marker
                height: 12,          // Height of the square marker
                shape: 'square',     // Set the shape to square
                radius: 0,           // No border-radius for square
                offsetX: -5,
            }
        }
    };

    const donutoptions = {
        chart: {
            height: 100,
            type: "radialBar",
        },
        plotOptions: {
            radialBar: {
                startAngle: -140,
                endAngle: 140,
                hollow: {
                    size: "65%",
                },
                track: {
                    background: "#332961", // Track background color
                    strokeWidth: "100%", // Track thickness
                    margin: 0, // Space between bar and track
                },
                dataLabels: {
                    name: {
                        show: true,
                        fontSize: "16px",
                    },
                    value: {
                        show: true,
                        fontSize: "16px",
                        color: "#fff",
                        offsetY: -10, // Center the value vertically
                        formatter: function (val) {
                            return val + "";
                        },
                    },
                },
                rounded: false, // Disable rounded corners

            },
        },
        colors: ["#533460"], // Single color for the segment
        stroke: {
            lineCap: "",
        },
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                type: "horizontal",
                shadeIntensity: 0.5,
                gradientToColors: ["#E6171D"],
                inverseColors: false,
                stops: [0, 100],
            },
        },
        labels: [""],
    };

    const getReletionshipTag = (score) => {
        let riskTag;
        if (score && score <= 25) {
            riskTag = "Critical";
        } else if (score && score <= 50) {
            riskTag = "High";
        } else if (score && score <= 75) {
            riskTag = "Medium";
        } else if (score && score > 75) {
            riskTag = "Low";
        }
        return riskTag;
    }

    return (
        <div className='top-first-tab'>
            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={8}>
                    <div className='overall-risk-overview-page overall-risk-overview-page-admin'>
                        <div className='flex-graph-half-donut' style={{ height: "100%" }}>
                            <div className='graph-risk'>
                                <p className='mtop-0 heading-new'>Overall Score</p>
                                <div className='graph-design-donut'>
                                    <div className='gauge-semicircle gauge-container' style={{ width: '390px', height: '200px' }}>
                                        {/* <span className="number-up" style={{ marginLeft: "67%", top: "90px" }}>
                        (<span className="number-up">
                          <img src={UpIcon} alt="up icon" />
                           {Math.round(riskScore)}%
                        </span>) :                       
                    </span> */}
                                        <GaugeComponent
                                            type="semicircle" // Using a semicircular gauge
                                            arc={{
                                                colorArray: [colorsNew.RED, colorsNew.ORANGE, colorsNew.YELLOW, colorsNew.GREEN,],
                                                padding: 0.06, // Adjust padding for smoother arc transitions
                                                subArcs: [
                                                    { limit: 25 },  // 25% of the total gauge
                                                    { limit: 50 },  // Next 25%
                                                    { limit: 75 },  // Next 25%
                                                    { limit: 100 }, // Last 25%
                                                ],
                                                arcWidth: 0.8, // Adjust arc thickness
                                            }}

                                            pointer={{ type: 'blob', animationDelay: 0, color: "#ffffff" }}
                                            // value={riskScore !== null ? Math.round(riskScore) : 0}
                                            value={overallRiskScore !== null ? Math.round(overallRiskScore) : 0}

                                            label={{
                                                display: true,
                                                text: `${Math.round(overallRiskScore)}/100`,
                                                style: {
                                                    fontSize: '25px',
                                                    fontWeight: 'bold',
                                                    fill: '#ffffff',
                                                    fontFamily: 'Mona-Sans',
                                                },
                                            }}
                                        />

                                        {latestScanDate && <p className='last-scan' style={{ textAlign: 'center', color: '#fff', marginTop: '0px' }}>
                                            {latestScanDate}
                                        </p>}

                                        {overallRiskScore && <div className={`${getReletionshipTag(overallRiskScore).toLowerCase()}-risk-part risk-part border-shape-color ${getReletionshipTag(overallRiskScore).toLowerCase()}-border-gradient`}>
                                            {overallRiskScore && <p>{getReletionshipTag(overallRiskScore)} Risk</p>}
                                        </div>}


                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                <div className="fullcover-width">
                                    {riskScore && <CircularProgress percent={riskScore} />}
                                </div>
                                <p className='outside-side-scan'>Outside in - Scan</p>
                            </div> */}

                            {/* <div className='inside-scan-div'> */}
                            {/* <p className='outside-side-scan mb-12-side'>Inside Out - Scan</p> */}
                            {/* <div className="fullcover-width"> */}
                            {/* <CircularProgress percent={sumScore} /> */}
                            {/* </div> */}

                            {/* <div className='Relationship Risk'> */}
                            {/* <div className='ranking-total' style={{ marginTop: "0px" }}> */}
                            {/* <h3>{totalScore}</h3> */}
                            {/* <p>Total Findings  </p> */}
                            {/* </div> */}
                            {/* </div> */}
                            {/* </div> */}

                            <div className='inside-scan-div'>
                                <p className='outside-side-scan mb-12-side'>Inside Out - Score</p>
                                <div className="fullcover-width">
                                    {!scoreLoader && insideScore && <CircularProgress percent={insideScore >  95 ? 95 : insideScore  || 0} />}
                                </div>
                                <div className="fullcover-width">
                                    {!scoreLoader && outsideScore && <CircularProgress percent={outsideScore || 0} />}
                                </div>
                                <p className='outside-side-scan'>Outside in - Score</p>
                            </div>

                            <div className='Relationship Risk'>
                                <p>Relationship Risk</p>
                                {/* {reletionShipRisk ? (<img src={getReletionshipImage(reletionShipRisk)} alt="icon" />)
                  : "-"} */}
                                {relationship ? (
                                    <span className={`tagClass ${getReletionshipTag(relationship)}`}>
                                        {getReletionshipTag(relationship)}
                                    </span>
                                ) : (
                                    "-"
                                )}
                                <div className='ranking-total'>
                                    <h3>{totalScore}</h3>
                                    <p>Total Findings</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <div className='same-base-bg'>
                        <div className="top-heading-dashboard">
                            <h2 style={{ margin: "13px 0px 0px" }}>Issue Distribution</h2>
                            <div className="legend-text-issue-legend top-space-middle-chart" style={{ marginTop: "40px" }}>
                                {(totalCritical + totalMedium + totalHigh + totalLow) > 0 ? (
                                    <Chart
                                        options={chartOptions2}
                                        series={chartSeries2}
                                        type="donut"
                                        height={180}
                                    />
                                ) : (
                                    <Chart
                                        options={getEmptyDonutOptions(chartOptions2)}
                                        series={[1]}
                                        type="donut"
                                        height={180}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </Grid>

                {/* compliances card */}
                <Grid item xs={12} md={6} lg={6}>
                    <div className='same-base-bg '>
                        <h2>Compliance</h2>
                        {complianceCardData?.length ? (
                            <Box className="Carousel-vertical-align-middle">
                                <Carousel
                                    navButtonsAlwaysVisible={true}
                                    autoPlay={false}
                                    indicators={false}
                                >
                                    {complianceCardData?.map((contentData, i) => {
                                        return (
                                            <Box
                                                key={i}
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Box
                                                    className="edit-show-compliance hippa-background"
                                                >
                                                    <Stack className="paddingstack-box">
                                                        <Grid container spacing={2}>
                                                            <Grid xs={3} sx={{ margin: "auto" }}>
                                                                <div className="bg-section-img-cover">
                                                                    <img
                                                                        className="compliance-logo"
                                                                        src={`${process.env.REACT_APP_BACKEND_BASE_URL}${contentData.complianceType == "Others"
                                                                            ? process.env.REACT_APP_STATIC_PATH_COMPLIANCE_CARD_LOGO
                                                                            : process.env.REACT_APP_STATIC_PATH_ASSETS
                                                                            }/${contentData?.logo}`}
                                                                        alt="icon"
                                                                    />
                                                                </div>
                                                            </Grid>
                                                            <Grid xs={9}>
                                                                <Typography className="view-complaince-heading-popup"> {contentData?.name}</Typography>
                                                                <Tooltip title={contentData?.description} arrow>
                                                                    <p className="discription-complaince-text">
                                                                        {contentData?.description
                                                                            ?.split(" ")
                                                                            .slice(0, 30)
                                                                            .join(" ")}{contentData?.description?.split(" ").length > 30 ? "..." : ""}
                                                                    </p>
                                                                </Tooltip>

                                                                <Link
                                                                    rel="noreferrer"
                                                                    target="_blank"
                                                                    to={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_COMPLIANCE_CARD_DOCUMENT}/${contentData?.document}`}
                                                                >
                                                                    <Button
                                                                        size="small"
                                                                        variant="contained"
                                                                        color="primary"
                                                                        component="label"
                                                                        className="show-document-complaince-button"
                                                                    >
                                                                        Show Attached Document
                                                                    </Button>
                                                                </Link>
                                                            </Grid>
                                                        </Grid>

                                                        <div className='inline-align-cards'>
                                                            <div>
                                                                <h3 className="textdeatil-complaince">Compliance Type</h3>
                                                                <p className="complaince-issues-heading ">{contentData?.complianceType}</p>
                                                            </div>
                                                            <div>
                                                                <h3 className="textdeatil-complaince">Issue Name</h3>
                                                                <p className="complaince-issues-heading ">{contentData?.issuerName}</p>
                                                            </div>
                                                            <div>
                                                                <h3 className="textdeatil-complaince">Issue Date</h3>
                                                                <p className="complaince-issues-heading">{contentData?.issueDate?.split('T')[0] || contentData?.issueDate}</p>
                                                            </div>
                                                            <div>
                                                                <h3 className="textdeatil-complaince">Expiry Date</h3>
                                                                <p className="complaince-issues-heading">{contentData?.expiryDate?.split('T')[0] || contentData?.expiryDate}</p>
                                                            </div>
                                                        </div>
                                                    </Stack>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Carousel>
                            </Box>
                        ) : (
                            <div className="no-card-data">
                                <p>No Compliance Data Found</p>
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <div className='same-base-bg' style={{ height: "59% !important" }}>
                        <h2>Outside In Score</h2>
                        {rows ?
                            (<TableContainer className='tablesame-outsidescore'>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Home</TableCell>
                                            <TableCell>Grade</TableCell>
                                            <TableCell>Risk Score</TableCell>
                                            <TableCell>Issues</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow>
                                                <TableCell sx={{
                                                    display: "flex",
                                                    alignItems: "center", gap: "10px", paddingBottom: "6.2%"
                                                }}><img src={DNS} />{row.category}</TableCell>
                                                <TableCell>
                                                    <span className={`gradClass ${row.grade}`}>
                                                        {row.grade}
                                                    </span>
                                                </TableCell>
                                                <TableCell className="relative radialBar-new">
                                                    {row.score ? <div className='radialBar mr-side0'>
                                                        <SemiChart id="radialBar-new-overviewpage" className="" options={{
                                                            ...donutoptions,
                                                            fill: {
                                                                ...donutoptions.fill,
                                                                gradient: {
                                                                    ...donutoptions.fill.gradient,
                                                                    gradientToColors: [row?.score <= 25 ? "#E6171D" : row?.score <= 50 ? "#B35405" : row?.score <= 75 ? "#B7AF08" : "#4FE71CB2"]
                                                                }
                                                            }
                                                        }} series={[row?.score]} type="radialBar" height={93} />
                                                        {/* {Number(row?.score) !== Number(row?.oldScore) && <span className={`number-up ${Number(row?.score) < Number(row?.oldScore) ? "number-down" : null}`}>
                              {(Number(row?.score) && Number(row?.oldScore)) ? <> <img src={
                                Number(row?.score) < Number(row?.oldScore) ? DownIcon : UpIcon} alt="icon" />{
                                  ((Math.abs(Number(row?.score) - Number(row?.oldScore)) * 100) / Number(row?.oldScore))?.toFixed(2) || 0}% </> : "0%"}
                            </span>} */}
                                                    </div> : "-"}

                                                </TableCell>
                                                <TableCell>
                                                    {row.issue}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>) :
                            (<Box sx={{ display: "flex", justifyContent: "center", minHeight: "190px", alignItems: "center", width: "100%" }}>
                                <CircularProgress />
                            </Box>
                            )}

                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default ClientOverviewHomeAdmin