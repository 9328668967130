import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import {
  TextField, InputAdornment, Box, IconButton, Menu, MenuItem, styled,
  FormControl, Select, CircularProgress, Typography, Tooltip, tooltipClasses
} from '@mui/material';
import AlertIcon from '../assets/alert-icon.svg';
import UpIcon from '../assets/up-icon.svg';
import SemiChart from "react-apexcharts";
import SearchIcon from '../assets/searchico.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EyeMenu from '../assets/eye-menu.svg';
import Trash from '../assets/trahs.svg';
import DownIcon from '../assets/down-arrow.svg';
import { useNavigate } from 'react-router-dom';
import defaultLogo from '../assets/companyLogo.jpg';
import PostRequest from '../components/apiConnections/postRequest';
import { useSnackbar } from "notistack";
import Delete from "../assets/delete.svg";
import ModalDialog from "./ModalDialog.js";
import TickGreen from '../assets/tick-green.svg';
import CrossRed from '../assets/cross-red.svg';
import GetRequest from '../components/apiConnections/getRequest.js';
import PutRequest from '../components/apiConnections/putRequest.js';
import { useSelector } from "react-redux";
import { CustomNoRowsOverlay } from './Assessment.js';

function DataGridLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        overflow: "hidden",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
// Donut chart options and series
const donutoptions = {
  chart: {
    height: 100,
    type: "radialBar",
  },
  plotOptions: {
    radialBar: {
      startAngle: -140,
      endAngle: 140,
      hollow: {
        size: "65%",
      },
      track: {
        background: "#332961", // Track background color
        strokeWidth: "100%", // Track thickness
        margin: 0, // Space between bar and track
      },
      dataLabels: {
        name: {
          show: true,
          fontSize: "16px",
        },
        value: {
          show: true,
          fontSize: "16px",
          color: "#fff",
          offsetY: -10, // Center the value vertically
          formatter: function (val) {
            return val + "";
          },
        },
      },
      rounded: false, // Disable rounded corners
    },
  },
  colors: ["#533460"], // Single color for the segment
  stroke: {
    lineCap: "",
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      gradientToColors: ["#E6171D"],
      inverseColors: false,
      stops: [0, 100],
    },
  },
  labels: [""],
};

// Menu component for the three-dot menu
function RowMenu({ handleRemove, handleView }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} className='three-dots-btn'>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className='rowmenu'
      >
        <MenuItem onClick={handleView} className='border-bot-line'> <img src={EyeMenu} /> View</MenuItem>
        <MenuItem onClick={handleRemove}> <img src={Trash} /> Remove</MenuItem>
      </Menu>
    </>
  );
}

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#333',
    color: 'white',
    fontSize: '14px',
    borderRadius: '8px',
    padding: '8px 12px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#333',
  },
}));
function DatagridVendor() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false)
  const [vendorList, setVendorList] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const roleId = parseInt(window.localStorage.getItem("roleId"));
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [filterSearch, setFilterSearch] = useState(null)
  const [rowCount, setRowCount] = useState(0);
  const [activeVendorUrl, setActiveVendorUrl] = useState(null)
  const [openStatusModal, setOpenStatusModal] = useState(false)
  const [openRejectModal, setOpenRejectModal] = useState(false)
  const [updateStatus, setUpdateStatus] = useState(null);
  let selector = useSelector((state) => state.permissionList.permissionList);

  const getGrad = (score) => {
    // (Number(score) <= 25 ? DScore : Number(score) <= 50 ? CGrade : Number(score) <= 75 ? BGrade : AGrade)
    let grade = ''
    if (Number(score) <= 25) {
      grade = 'D';
    } else if (Number(score) <= 50 && Number(score) > 25) {
      grade = 'C';
    } else if (Number(score) <= 75 && Number(score) > 50) {
      grade = 'C';
    } else {
      grade = 'A';
    }
    return grade;
  }
  const columns = [
    {
      field: 'id',
      headerName: '#',
      flex: 0.5,
      className: 'serial-number',
      disableColumnMenu: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1.1,
      minWidth: 170,  // Ensure minimum width
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
          {(params.row.acceptableRisk !== null && Number(params.row.score) && Number(params.row.score) < params.row.acceptableRisk) ? <img src={AlertIcon} alt="icon" /> : null}
          <span className="logo-image-vendor">
           {params.row?.logo !=='null' ? <img className="attack-surface-list-logo" src={params.row?.logo} alt="Figma"/> :<img className="attack-surface-list-logo" src= {`${process.env.REACT_APP_GET_LOGO_BY_THIRD_API}/${params?.row?.target}`}  alt="Img" onError={(e) => {
                e.target.onerror = null;
                e.target.src = defaultLogo;
              }}/>}
          </span>
          <span style={{ display: 'flex', gap: '6px' }}>
            {params.value}
          </span>
        </div>
      ),
    },
    {
      field: 'vendorCurrentStatus', headerName: 'Status', flex: 1,
      minWidth: 125,
      disableColumnMenu: true,
      renderCell: (params) => {
        let statusClass = '';

        switch (params.value) {
          case 'Onboarding':
            statusClass = 'onboard-green';
            break;
          case 'Due diligence':
            statusClass = 'delayed';
            break;
          case 'Monitoring':
            statusClass = 'inProgress';
            break;
          case 'Deboarding':
            statusClass = 'deboarding';
            break;
          default:
            statusClass = '';
        }

        return <span className={statusClass}>{params.value}</span>;
      }

    },
    {
      field: 'grade', headerName: 'Grade',
      flex: 0.5,
      minWidth: 70,
      disableColumnMenu: true,
      renderCell: (params) => {
        const score = params.row?.score;
        return (<>
        {/* {(score && Number(score)) ? <img src={
          (Number(score) <= 25 ? DScore : Number(score) <= 50 ? CGrade : Number(score) <= 75 ? BGrade : AGrade)
        } alt='icon' /> : "-"} */}
          <span className={`gradClass ${getGrad(score)}`}>
          {getGrad(score)}
          </span>
        </>
        )
      }
    },
    {
      field: 'score',
      headerName: 'Risk Score',
      flex: 1,
      minWidth: 110,  // Ensure minimum width
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className="relative radialBar-new">
          {params.value ? <>
            <SemiChart id="radialBar-new" options={{
              ...donutoptions,
              fill: {
                ...donutoptions.fill,
                gradient: {
                  ...donutoptions.fill.gradient,
                  gradientToColors: [params.value <= 25 ? "#E6171D" : params.value <= 50 ? "#B35405" : params.value <= 75 ? "#B7AF08" : "#4FE71CB2"]
                }
              }
            }} series={[params.value]} type="radialBar" height={93} />
            <span className={`number-up ${Number(params.value) < Number(params.row?.previousScore) ? "number-down" : null}`}>
              {(Number(params.value) && Number(params.row?.previousScore)) ? <> <img src={
                Number(params.value) < Number(params.row?.previousScore) ? DownIcon : UpIcon} alt="icon" />{
                  ((Math.abs(Number(params.value) - Number(params.row?.previousScore)) * 100) / Number(params.row?.previousScore))?.toFixed(2)}%</> : null}
            </span>
          </> : "-"}

        </div>
      ),
    },
    {
      field: 'severity', headerName: 'Severity', flex: 1,
      minWidth: 110,
      disableColumnMenu: true,
      renderCell: (params) => {
        let score = params.row.score;
        let scoreTag = "-";
        if (score && score <= 25) {
          scoreTag = "Critical";
        } else if (score && score <= 50) {
          scoreTag = "High";
        } else if (score && score <= 75) {
          scoreTag = "Medium";
        } else if (score && score > 75) {
          scoreTag = "Low";
        }
        return (
          scoreTag ? (
            <span className={`tagClassapproval ${scoreTag}`}>
              <span>{scoreTag}</span>
            </span>
          ) : (
            "-"
          )
        );
      }
    },
    {
      field: 'failedControls', headerName: 'Failed Controls',
      flex: 1, minWidth: 150,
      disableColumnMenu: true,
      renderCell: (params) => <span className="cloud-vpn-btn">{params.value || 0}</span>                               // <img src={Medium} alt='icon' />
    },
    {
      flex: 1,
      width: 160,
      disableColumnMenu: true,
      renderCell: (params) => (
        <span className='icons-td'>

          {/* <CustomTooltip title="Extend Deadline" placement="top" arrow>
            <img src={Reload} alt="Reload" />
          </CustomTooltip> */}
          {checkPermission("Approve/Decline Ecosystem") &&
            <>
              <CustomTooltip title="Approve" placement="top" arrow onClick={() => { setUpdateStatus("Monitoring"); setOpenStatusModal(true); setActiveVendorUrl(params.row.target) }}>
                <img src={TickGreen} alt="Approve" />
              </CustomTooltip>

              <CustomTooltip title="Reject" placement="top" arrow onClick={() => { setUpdateStatus("Deboarding"); setOpenRejectModal(true); setActiveVendorUrl(params.row.target) }}>
                <img src={CrossRed} alt="Reject" />
              </CustomTooltip>
            </>
          }
        </span>
      ),
    },
  ];

  const checkPermission = (value) => {
    if (!localStorage.getItem("icu") || localStorage.getItem("icu") == "0") {
      return true;
    }
    else if (selector.includes(value)) return true;
    else return false;
  }

  useEffect(() => {
    getVendorDetails(paginationModel.page + 1, paginationModel.pageSize);
  }, [paginationModel]);

  useEffect(() => {
    let time = setTimeout(() => {
      if (filterSearch !== null) getVendorDetails(1, paginationModel.pageSize)
    }, 500)
    return () => clearTimeout(time)
  }, [filterSearch])

  const getVendorDetails = async (page, limit) => {
    setShowLoader(true);
    setVendorList([])
    const endpoint = process.env.REACT_APP_GET_VENDOR_FOR_APPROVAL;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}?page=${page
          }&limit=${limit}&search=${filterSearch || ""}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data;
            setRowCount(arrResponse?.total || 0);
            if (Array.isArray(arrResponse?.data)) setVendorList(
              arrResponse?.data?.length
                ? [
                  ...arrResponse?.data?.map((d, i) => ({
                    ...d,
                    id: i + 1,
                    name:
                      d.name
                        ?.substring(0, d.name?.indexOf("."))
                        .charAt(0)
                        .toUpperCase() +
                      d.name
                        ?.substring(0, d.name?.indexOf("."))
                        .substr(1)
                        .toLowerCase(),
                    risk:
                      d.score == "-" ? "-" :
                        d.score <= 25
                          ? "Critical"
                          : d.score <= 50
                            ? "High"
                            : d.score <= 75
                              ? "Medium"
                              : "Low",
                    status: d.status ? "Fulfilled" : "Awaiting"
                  })),
                ]
                : []
            );
            setShowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setShowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const updateVendor = async () => {
    setShowLoader(true);
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      const endpoint = process.env.REACT_APP_UPDATE_VENDOR_STATUS;
      const payload = {
        url: activeVendorUrl,
        status: updateStatus,
        deboard: false
      };
      await PutRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        payload
      )
        .then((resVendors) => {
          if (resVendors.data.code == 200) {
            enqueueSnackbar(resVendors.data.message, { variant: "success" });
            setShowLoader(false);
            setActiveVendorUrl(null)
            setOpenStatusModal(false)
            setOpenRejectModal(false)
            setUpdateStatus(null)
            setTimeout(() => {
              getVendorDetails(paginationModel.page + 1, paginationModel.pageSize)
            }, 1000);
          } else {
            setShowLoader(false);
            enqueueSnackbar(resVendors.data.message, { variant: "error" });
          }
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: "error" });
          setShowLoader(false);
        });
    }
  };

  const handleEntriesChange = (event) => {
    setEntriesPerPage(event.target.value);
  };

  const handleSearch = (event) => {
    setFilterSearch(event.target.value)
  };

  const handleRowClick = (params) => {
    console.log("clieck")
    navigate(`/${roleId === 2 ? "admin" : "dashboard"}/vendorDetails/${params.row.target}`)
  };
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  const onFilterChange = (value) => {
    let searchQuery = value.quickFilterValues[0] || ""
    setFilterSearch(searchQuery)
  }
  const statusModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h3 className="send-assessments" style={{ whiteSpace: "nowrap" }}>
          Are you sure you want to approve vendor ?
        </h3>
      </Typography>
    );
  };
  const rejectModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h3 className="send-assessments" style={{ whiteSpace: "nowrap" }}>
          Are you sure you want to reject vendor ?
        </h3>
      </Typography>
    );
  }
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '-9px', left: '13px' }}>
        <FormControl className='select-entries' variant="outlined" size="small">
          <Select
            value={paginationModel.pageSize}
            onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
          </Select>
        </FormControl>
        <span className='entries-per'>entries per page</span>
      </div>
      <Box
        sx={{
          width: '100%',
          '& .MuiDataGrid-columnHeaders': {
            minWidth: '100%',
            overflowX: 'hidden',
          },
          '& .MuiDataGrid-virtualScroller': {
            minWidth: '100%',
            overflowX: 'auto',
          },
        }}
      >
        <TextField
          variant="outlined"
          placeholder="Search…"
          value={filterSearch}
          onChange={handleSearch}
          className='searh-text-field'
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={SearchIcon} alt='icon' />
              </InputAdornment>
            ),
          }}
        />
        <DataGrid
          rows={vendorList} // Ensure you're using the filtered rows
          columns={columns}
          rowHeight={70}
          paginationMode="server"
          pageSizeOptions={[5, 10, 25]}
          onPaginationModelChange={handlePaginationModelChange}
          paginationModel={paginationModel}
          rowCount={rowCount}
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
            loadingOverlay: DataGridLoader,
          }}
          loading={showLoader}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          disableSelectionOnClick
          disableRowSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          autoHeight
          className='datagrid-table'
          sx={{
            '& .MuiDataGrid-columnHeaders': {
              minWidth: '100%', // Column headers take up full width
            },
            '& .MuiDataGrid-virtualScroller': {
              minWidth: '100%', // Rows take up full width
            },
          }}
        // onRowClick={handleRowClick}
        />
      </Box>
      <ModalDialog
        handleClose={() => { setOpenStatusModal(false); setActiveVendorUrl(null) }}
        open={openStatusModal}
        onSubmit={updateVendor}
        contentData={statusModalContent}
      />
      <ModalDialog
        handleClose={() => { setOpenRejectModal(false); setActiveVendorUrl(null) }}
        open={openRejectModal}
        onSubmit={updateVendor}
        contentData={rejectModalContent}
      />
    </>
  );
}

export default DatagridVendor;