import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import Profile from "../sections/auth/profile/Profile";
import Mail from "../assets/mail-icon.svg";
import Phone from "../assets/phone-line.svg";
import SMS from "../assets/message-icons.svg";

import './css/Profile.css';
import {
    Card,
    Box,
    Grid,
    Stack,
    TextField,
    Button,
    Container,
} from "@mui/material";
import UserProfile from "./../assets/user-profile.svg";
import PostRequest from '../components/apiConnections/postRequest';
import GetRequest from '../components/apiConnections/getRequest';
import { useSnackbar } from 'notistack';
import { getLocalStoarge } from '../components/common/allFunctions';
import NewHeader from "../layouts/dashboard/header/NewHeader";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

export default function ProfileViewPage() {
    const { enqueueSnackbar } = useSnackbar();
    const [userDetail, setClientDetail] = React.useState();
    const [sendOtp, setSendOtp] = useState(false);
    const [verifyOtp, setVerifyOtp] = useState(false);
    const [emailField, setEmailField] = useState(true);
    const [showLoader, setShowLoader] = useState(false);
    const [OTP, setOTP] = useState('');
    const [emailText, setEmailText] = useState('Get One time code sent on email to complete the authentication requests');
    const [mobileText, setMobileText] = useState('Get One time code sent on mobile to complete the authentication requests');

    useEffect(() => {
        getClientData();
    }, [])
    const navigate = useNavigate();
    const addVerification = () => {
        setSendOtp(true);
        if (userDetail.twoFAMethod == 'email') {
            setEmailField(true)
        } else {
            setEmailField(false)
        }
    }
    const cancelVerification = () => {
        setSendOtp(false);
        setVerifyOtp(false);
    }

    // Handle input change
    const handleOtp = (event) => {
        setOTP(event.target.value); // Set the new value into the state variable
    };

    const sendUserOtp = async (e) => {
        e.preventDefault();
        try {

            let payload = { ...userDetail, clientId: userDetail._id };
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_CLIENT_VERIFICATION_CODE}`,
                    payload
                )
                    .then((resChart) => {
                        setVerifyOtp(true)
                        setSendOtp(false)
                        enqueueSnackbar(resChart.data?.message, { variant: "success" });
                        setShowLoader(false);
                    })
                    .catch((errChart) => {
                        enqueueSnackbar(errChart.message, { variant: "error" });
                        setShowLoader(false);
                    });
            } else {
                setShowLoader(false);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            setShowLoader(false);
        }
    }

    const veriFyOTP = async (e) => {
        e.preventDefault();
        try {

            let payload = { otp: OTP };
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_VERIFY_CLIENT_CODE}`,
                    payload
                )
                    .then((resChart) => {
                        setVerifyOtp(false)
                        setOTP('')
                        getClientData()

                        enqueueSnackbar(resChart.data?.message, { variant: "success" });
                        setShowLoader(false);
                    })
                    .catch((errChart) => {
                        enqueueSnackbar(errChart.message, { variant: "error" });
                        setShowLoader(false);
                    });
            } else {
                setShowLoader(false);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            setShowLoader(false);
        }
    }
    const getClientData = async () => {
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_CLIENT_DATA}?clientId=${getLocalStoarge('userId')}`
                )
                    .then((resChart) => {
                        let userDetail = resChart.data?.data;
                        setClientDetail(userDetail)
                    })
                    .catch((errChart) => {
                        enqueueSnackbar(errChart.data?.message, { variant: "error" });
                        // setShowLoader(false);
                    });
            } else {
                // setShowLoader(false);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            // setShowLoader(false);
        }
    };

    return (
        <>
            <Helmet>
                <title> Profile | Genesis Platform</title>
            </Helmet>
            <Container maxWidth={false}>
                <NewHeader titleName="My Profile" />
                <Box alignItems="center" className="center-alignmy-profile">
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2} lg={2} className="div-profile-image-center">
                            <div>
                                <Box
                                    className="center-upload-image-fix-size"
                                    component="img"
                                    alt="Profile Image"
                                    src={
                                        typeof userDetail?.profilePic === "string"
                                            ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${userDetail?.profilePic}`
                                            : ""
                                    }
                                    onError={(e) => e.target.src = UserProfile}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <div alignItems="center" className="my-profile-text-size">
                        <h1>{userDetail?.firstName}  {userDetail?.lastName}</h1>
                        <p><img src={Mail} width={20} height={20} alt="icon" /> {userDetail?.emailId}</p>
                        <p><img src={Phone} width={20} height={20} alt="icon" />{userDetail?.mobile}</p>
                    </div>

                </Box>
                <div style={{ display: "flex", justifyContent: "center", }}>
                    <Button
                        sx={{ mt: 2, }}
                        variant="contained"
                        className="edit-myaccount-button"
                        onClick={() => navigate("../update-profile", { state: userDetail })}
                    >
                        Edit Profile
                    </Button>
                </div>

                {userDetail?.twoFAMethod == 'sms' &&
                    <div>
                        <h2 className="twofa-setup">2FA Setup</h2>
                        <form className="profile-form">
                            <Card sx={{ mt: 0, mb: 5, width: "600px" }}>
                                {!sendOtp && !verifyOtp && <div>
                                    {/* style={{ display: "flex", alignItems:"center", minWidth:"100%", justifyContent:"space-between",}} */}
                                    <div >
                                        {!userDetail?.twoFAConfigure_change &&
                                            <span>
                                                {/* {userDetail?.twoFAMethod == 'email' ?
                                                    <div className="email-twofa">
                                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                            <img src={Mail} width={35} height={35} alt="icon" />
                                                            <div>
                                                                <h3>Email  {userDetail?.twoFAConfigured && (!sendOtp || !verifyOtp) ? <Button className="configured-label-tag-account">configured</Button> : null}</h3>
                                                                {emailText}
                                                            </div>
                                                        </div>
                                                    </div> : null
                                                } */}

                                                {userDetail?.twoFAMethod == 'sms' ?
                                                    <div className="email-twofa">
                                                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                            <img src={SMS} width={35} height={35} alt="icon" />
                                                            <div>
                                                                <h3>SMS  {userDetail?.twoFAConfigured && (!sendOtp || !verifyOtp) ? <Button className="configured-label-tag-account">configured</Button> : null}</h3>
                                                                {mobileText}</div>
                                                        </div>
                                                    </div> :
                                                    null
                                                }
                                            </span>}
                                        {/* Add Button  */}
                                        {!sendOtp && !userDetail?.twoFAConfigured && !userDetail?.twoFAConfigure_change && <div>
                                            <LoadingButton
                                                size="medium"
                                                type="submit"
                                                variant="contained"
                                                color="success"
                                                className="edit-myaccount-button"
                                                onClick={addVerification}
                                            >
                                                Add
                                            </LoadingButton>
                                        </div>}
                                    </div>


                                    {!sendOtp && <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between", }}>
                                        {userDetail?.twoFAConfigure_change ?
                                            <span>
                                                {/* {userDetail?.twoFAMethod == 'email' ? <div className="email-twofa">
                                                <img src={Mail} width={35} height={35} alt="icon" />
                                                <div>
                                                    <h3>Email </h3>
                                                    {emailText}</div></div> : null} */}

                                                {userDetail?.twoFAMethod == 'sms' ? <div className="email-twofa">
                                                    <img src={SMS} width={35} height={35} alt="icon" />
                                                    <div>
                                                        <h3>SMS</h3>
                                                        {mobileText}
                                                    </div>
                                                </div> : null}
                                            </span> : null}
                                        <div>
                                            {!sendOtp && userDetail?.twoFAConfigure_change && !verifyOtp ?
                                                <LoadingButton
                                                    size="medium"
                                                    type="submit"
                                                    variant="contained"
                                                    color="success"
                                                    className="edit-myaccount-button"
                                                    onClick={addVerification}
                                                >
                                                    Edit
                                                </LoadingButton> : null}
                                        </div>
                                    </div>}

                                    {sendOtp &&
                                        <LoadingButton
                                            size="medium"
                                            type="submit"
                                            variant="contained"
                                            color="success"
                                            className="edit-myaccount-button"
                                            onClick={cancelVerification}
                                        >
                                            Cancel
                                        </LoadingButton>}
                                </div>}
                                {sendOtp && <div>

                                    {/* {emailField ? <>
                                        <div className="email-twofa">
                                            <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
                                                <img src={Mail} width={35} height={35} alt="icon" />
                                                <div>
                                                    <h3>Email</h3>
                                                    {emailText}</div></div>
                                            <Button className="edit-myaccount-button" onClick={cancelVerification}>Cancel</Button>
                                        </div>

                                        <h3 className="margin-left-email-ids"> Send A verification code on your email to verify and enable email as 2FA method</h3></> : null} */}

                                    {!emailField ? <>
                                        <div className="email-twofa">
                                            <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
                                                <img src={SMS} width={35} height={35} alt="icon" />
                                                <div>
                                                    <h3>SMS</h3>
                                                    {mobileText}</div></div>
                                            <Button className="edit-myaccount-button" onClick={cancelVerification}>Cancel</Button>
                                        </div>
                                        <h3 className="margin-left-email-ids"> Send A verification code on your mobile to verify and enable mobile as 2FA method</h3> </> : null}

                                    <div className="margin-left-email-ids">

                                        <Box>
                                            <Grid container spacing={2}>
                                                <Grid item xs={10} md={10} lg={10}>
                                                    <Stack spacing={2}>
                                                        <Grid container spacing={2} style={{ alignItems: "center" }}>
                                                            {/* {emailField && <Grid item xs={10} md={6} lg={6} pb={1}>
                                                                <TextField
                                                                    name="email"
                                                                    value={userDetail.emailId}
                                                                    label="Email ID"
                                                                    inputProps={{ maxLength: 15 }}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    disabled={true}
                                                                />
                                                            </Grid>} */}

                                                            {!emailField && <Grid item xs={10} md={6} lg={6} pb={1}>
                                                                <TextField
                                                                    name="mobile"
                                                                    value={userDetail.mobile}
                                                                    label="Mobile"
                                                                    inputProps={{ maxLength: 15 }}
                                                                    InputLabelProps={{ shrink: true }}
                                                                    disabled={true}
                                                                />
                                                            </Grid>}

                                                            <Grid item xs={8} md={4} lg={4} pb={1}>
                                                                <LoadingButton
                                                                    size="medium"
                                                                    type="submit"
                                                                    variant="contained"
                                                                    color="success"
                                                                    className="edit-myaccount-button"
                                                                    onClick={sendUserOtp}
                                                                >
                                                                    Send
                                                                </LoadingButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </div>
                                </div>
                                }
                                {verifyOtp &&
                                    <div>

                                        {/* {emailField ?
                                            <div className="email-twofa">
                                                <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
                                                    <img src={Mail} width={35} height={35} alt="icon" />
                                                    <div>
                                                        <h3>Email</h3>
                                                        {emailText}</div></div>
                                                <Button className="edit-myaccount-button" onClick={cancelVerification}>Cancel</Button>
                                            </div> : null} */}

                                        {!emailField ?
                                            <div className="email-twofa">
                                                <div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: "10px" }}>
                                                    <img src={SMS} width={35} height={35} alt="icon" />
                                                    <div>
                                                        <h3>SMS</h3>
                                                        {mobileText}</div></div>
                                                <Button className="edit-myaccount-button" onClick={cancelVerification}>Cancel</Button>
                                            </div> : null
                                        }
                                        <div className="margin-left-email-ids">
                                            <h3> Verify OTP to make 2FA configured</h3>
                                            <Box>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={10} md={10} lg={10}>
                                                        <Stack spacing={2}>
                                                            <Grid container spacing={2} style={{ alignItems: "center" }}>
                                                                <Grid item xs={10} md={6} lg={6} pb={1}>
                                                                    <TextField
                                                                        name="otp"
                                                                        label="OTP"
                                                                        value={OTP}
                                                                        inputProps={{ maxLength: 15 }}
                                                                        InputLabelProps={{ shrink: true }}
                                                                        onChange={handleOtp}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={8} md={4} lg={4} pb={1}>
                                                                    <LoadingButton
                                                                        size="medium"
                                                                        type="submit"
                                                                        variant="contained"
                                                                        color="success"
                                                                        className="edit-myaccount-button"
                                                                        onClick={veriFyOTP}
                                                                    >
                                                                        Verify
                                                                    </LoadingButton>
                                                                </Grid>
                                                            </Grid>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </div>
                                    </div>}
                            </Card>
                        </form>
                    </div>
                }
                {userDetail?.twoFAMethod == 'email' ?
                    <div>
                        <h2 className="twofa-setup">2FA Setup</h2>
                        <form className="profile-form">
                            <Card sx={{ mt: 0, mb: 5, width: "600px" }}>
                                <div >
                                    <span>
                                        <div className="email-twofa">
                                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                <img src={Mail} width={35} height={35} alt="icon" />
                                                <div>
                                                    <h3>Email<Button className="configured-label-tag-account">configured</Button></h3>
                                                    {emailText}
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </Card>
                        </form>
                    </div>
                    : null}
            </Container>
        </>
    );
}
