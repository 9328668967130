// FaildeControl.js
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
import { Select } from "@mui/material";
import { CustomNoRowsOverlay } from "../Assessment.js"
import {
  Box,
  Card,
  MenuItem,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import GetRequest from "../../components/apiConnections/getRequest.js";

const PassedControl = () => {
  const data = useParams();
  const navigate = useNavigate();
  const [passedControlData, setPassedControlData] = useState([]);
  const [showLoader, setshowLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    getVendorPassedControl();
  }, []);

  const getVendorPassedControl = async () => {
    setshowLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_ASSESSMENT_PASSED_CONTROL}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.passesQuestions;
          setPassedControlData(obj2);
          setshowLoader(false);
        })
        .catch((err) => {
          // no data
          setshowLoader(false);
          enqueueSnackbar("Passed controls not find", { variant: "error" });
          
          return false;
        });
    } catch (error) {
      // no data
      setshowLoader(false);
      enqueueSnackbar("Passed controls not find", { variant: "error" });
      
      return false;
    }
  };

  const handleRowClick = (params) => {
    navigate(`/dashboard/failed-control-detail`, { state: { Data: params.row , vendorUrl: vendorUrl,   mainTabValue: 4, nestedTabValue: 0  }})
  };

  const filteredRows =
    (!passedControlData || !passedControlData.length) ? [] :
      passedControlData
        ?.map((val, i) => ({
          ...val,
          id: i + 1,
          text: val?.que?.text,
          score: val?.score > 0 ? "Passed" : "Failed",
          category: val?.category,
          riskMapping: val?.que?.riskMapping,
          set: val?.set,
          uniqueId: val?.que?.uniqueId || "--"
        }));
  const columns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.2,
      className: "serial-number",
      disableColumnMenu: true,
    },
    {
        field: "uniqueId",
        headerName: "ID",
        flex: 0.5,
        minWidth: 120, 
        disableColumnMenu: true,
    },
    {
      field: "text",
      headerName: "Questions Name",
      flex: 2,
      minWidth: 125,
      disableColumnMenu: true,
    },
    {
      field: "score",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        let score = params.value;
        return (
          <span className={`tagClass Passed`}>{score}</span>
        );
      },
    },
    {
      field: "category",
      headerName: "Type",
      flex: 0.9,
      minWidth: 100,
      disableColumnMenu: true,
    },
    {
      field: "riskMapping",
      headerName: "Risk",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        let riskValue = params.value;
    
        return riskValue ? (
          <span className={`tagClassapproval ${riskValue}`}>
           <span> {riskValue}</span>
          </span>
        ) : (
          "-"
        );
      }
    },
    {
      field: "set",
      headerName: "Assessment",
      flex: 1,
      minWidth: 100,
      align: "left",
      headerAlign:"left",
      disableColumnMenu: true,
    }
  ];
  return (
    <>
      <Card className="full-coverdesign margin-questionnaire">
        <div className="cover-table">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              top: "0px",
              left: "8px",
            }}
          >
            <FormControl
              className="select-entries"
              variant="outlined"
              size="small"
            >
              <Select
                value={paginationModel.pageSize}
                onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <span className="entries-per">entries per page</span>{" "}
          </div>

          <div className="issues-tab-table">
            <Box
              sx={{
                width: "100%",
                "& .MuiDataGrid-columnHeaders": {
                  minWidth: "100%",
                  overflowX: "hidden",
                },
                "& .MuiDataGrid-virtualScroller": {
                  minWidth: "100%",
                  overflowX: "auto",
                },
              }}
            >

              <DataGrid
                loading={showLoader}
                rows={filteredRows}
                columns={columns}
                rowHeight={70}
                onPaginationModelChange={handlePaginationModelChange}
                paginationModel={paginationModel}
                components={{
                  NoRowsOverlay: () => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      No data
                    </Box>
                  ),
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [],
                    },
                  },
                }}
                autosizeOptions={{
                  columns: ["id", "firstName", "lastName"],
                  includeOutliers: true,
                  includeHeaders: false,
                }}
                pageSizeOptions={[5, 10, 25]}
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
                paginationMode="client"
                disableSelectionOnClick
                autoHeight
                className="datagrid-table click-hover-datagrid"
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    minWidth: "100%", // Column headers take up full width
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    minWidth: "100%", // Rows take up full width
                  },
                }}
                onRowClick={handleRowClick}
              />
            </Box>
          </div>
        </div>
      </Card>
    </>
  );
};

export default PassedControl;
