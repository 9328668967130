import {
  Stack,
  Typography,
  Box,
  Modal,
  Button,
  Grid,
  Link,
} from "@mui/material";
import React, { useState } from "react";
import "../../pages/css/AddFile.css";
import CloseIcon from "@mui/icons-material/Close";
import ProfileCyber from '../../assets/cyber-security.png';
function ShowCompliance({ open, handleClose, contentData }) {
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={
          theme == "Dark" ? "dark-modal modal-popup" : "light-modal modal-popup"
        }
      >
        <Box
          className="edit-show-compliance"
        >
          <div className="show-complaince-popup">
            <h3>Compliance View</h3>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>
          <Stack className="paddingstack-box">
            <Grid container spacing={2}>
              <Grid xs={3} sx={{ margin: "auto" }}>
                <div className="bg-section-img-cover">
                  <img
                    className="compliance-logo"
                    src={`${process.env.REACT_APP_BACKEND_BASE_URL}${contentData.complianceType == "Others"
                        ? process.env.REACT_APP_STATIC_PATH_COMPLIANCE_CARD_LOGO
                        : process.env.REACT_APP_STATIC_PATH_ASSETS
                      }/${contentData?.logo}`}
                    alt="icon"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = ProfileCyber;
                    }}
                  />
                </div>
              </Grid>
              <Grid xs={9}>
                <Typography className="view-complaince-heading-popup"> {contentData?.name}</Typography>
                <p className="discription-complaince-text">{contentData?.description}</p>
                <Link
                  target="_blank"
                  href={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH_COMPLIANCE_CARD_DOCUMENT}/${contentData?.document}`}
                >
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    component="label"
                    className="show-document-complaince-button"
                  >
                    Show Attached Document
                  </Button>
                </Link>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: "35px" }}>
              <Grid xs={3}>
                <h3 className="textdeatil-complaince">Compliance Type</h3>
                <p className="complaince-issues-heading ">{contentData?.complianceType}</p>
              </Grid>
              <Grid xs={3}>
                <h3 className="textdeatil-complaince">Issue Name</h3>
                <p className="complaince-issues-heading ">{contentData?.issuerName}</p>
              </Grid>
              <Grid xs={3}>
                <h3 className="textdeatil-complaince">Issue Date</h3>
                <p className="complaince-issues-heading">{contentData?.issueDate}</p>
              </Grid>
              <Grid xs={3}>
                <h3 className="textdeatil-complaince">Expiry Date</h3>
                <p className="complaince-issues-heading">{contentData?.expiryDate}</p>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default ShowCompliance;
