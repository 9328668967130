import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import ReactApexChart from 'react-apexcharts';
import { useSnackbar } from "notistack";
import GetRequest from "../../components/apiConnections/getRequest";

function ComplianceMappingPage({ domainName, assessmentId }) {
  const [loader, setLoader] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [compliances, setComplainces] = useState();
  const [totalMappedQuestions, setTotalMappedQuestions] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [complinceLoader, setComplinceLoader] = useState(false);

  const controller = compliances && Object.entries(compliances).map((control, index) => {
    return {
      name: control[0],
      value: control[1]?.total,
      series: [
        control[1]?.totalControls > 0 ? Math.round((control[1].passed / control[1].totalControls) * 100) : 0,
        control[1]?.totalControls > 0 ? Math.round((control[1].failed / control[1].totalControls) * 100) : 0,
        control[1]?.totalControls > 0 ? Math.round((control[1].total / control[1].totalControls) * 100) : 0
      ]
    }
  });


  useEffect(() => {
    getCompplianceMapping();
  }, []);

  // Define options for the concentric radial bars
  const options1 = (control) => ({
    chart: {
      type: 'radialBar',
      height: 350,
      background: 'transparent', // Set chart background to transparent
      rounded: true,
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: -135,
        endAngle: 135,
        hollow: {
          margin: 10,
          size: '60%',
          background: 'transparent', // Make hollow background transparent
          strokeCap: 'round',
          rounded: true,
        },
        track: {
          background: 'transparent', // Make track background transparent
          strokeWidth: '97%',
          margin: 6,
          strokeCap: 'round',
        },
        dataLabels: {
          total: {
            show: true,
            label: 'Total',
            fontFamily: 'Mona-Sans',
            color: '#fff', // Ensure label text is white
            offsetY: -10,
            formatter: function (w) {
              return `${control?.value} Controls`
            }
          },
          value: {
            fontSize: '18px',
            color: '#fff', // Ensure value text is white
            fontFamily: 'Mona-Sans',
          },
          name: {
            fontSize: '20px',
            color: '#fff', // Ensure name text is white
            fontFamily: 'Mona-Sans',
          },
        },
        rounded: true,
      }
    },
    labels: ['Passed', 'Failed Controls', 'Mapping'],
    colors: ['#009420', '#E22222', '#0090FF'], // Customize colors for each radial bar
    legend: {
      show: true,
      position: 'bottom',
      offsetY: 10,
      horizontalAlign: 'center',
      markers: {
        width: 12,
        height: 12,
        radius: 0,
        offsetY: -1,
        offsetX: -3,
        shape: "square",
        fillColors: ['#009420', '#E22222', '#0090FF'], // Set custom marker colors
      },
      labels: {
        colors: '#fff',  // Set the legend text color to white
      },
      itemMargin: {
        horizontal: 10,
      },
    },
  });

  const getCompplianceMapping = () => {
    setLoader(true);
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMPLIANCE_MAPPING}?domain=${domainName}&assessmentId=${assessmentId}`
      )
        .then((res) => {
          setComplainces(res.data.compliances)
          setTotalMappedQuestions(res.data.totalComplianceMappedQuestions);
          setTotalQuestions(res.data.totalQuestions || 0);
          setComplinceLoader(false);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          return false;
        });
    } catch (error) {
      setLoader(false);
      return false;
    }
  };

  return (
    <>

      <h3 className="category-score-text-heading" style={{ marginTop: "0px", }}>Mapping</h3>
      <Grid
        container
        spacing={3}
        style={{ marginTop: '40px', marginBottom: '20px' }}
      >
        {controller && controller.map((control, index) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={control.name || index}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <div className="gdpr-box">
              <h3>{control.name}</h3>
              <ReactApexChart
                options={options1(control)}
                series={control.series}
                type="radialBar"
                height={350}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default ComplianceMappingPage;
