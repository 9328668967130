import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import "./css/Vendors.css";
import "./css/Attack.css";
// @mui
import {
  TextField,
  InputAdornment,
  Card,
  MenuItem,
  Container,
  Typography,
  Box,
  FormControl,
  Select,
  IconButton,
  Menu, Grid,
} from "@mui/material";
import PostRequest from "../components/apiConnections/postRequest";
import { useSnackbar } from "notistack";
import ModalDialog from "./ModalDialog.js";
import Delete from "../assets/delete.svg";
import NewHeader from "../layouts/dashboard/header/NewHeader.js";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "./Assessment.js"
import { useNavigate } from "react-router-dom";
import EyeMenu from '../assets/eye-menu.svg';
import Trash from '../assets/trahs.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '../assets/searchico.svg';
import defaultLogo from '../assets/companyLogo.jpg';
// ----------------------------------------------------------------------
export default function UserPage() {
  const navigate = useNavigate()
  const [vendorsData, setVendorsData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setshowLoader] = useState(false);
  let roleId = parseInt(window.localStorage.getItem('roleId'));
  const [filterSearch, setFilterSearch] = useState(null)
  const [rowCount, setRowCount] = useState(0)
  const [userRemoveId, setUserRemoveId] = useState(0);
  const [openUser, setOpenUser] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  useEffect(() => {
    setOpenUser((userRemoveId !== 0) ? true : false);
  }, [userRemoveId])

  const handleOpen = (url) => {
    setUserRemoveId(url);
  };
  const handleClose = () => {
    setOpenUser(false)
    setUserRemoveId(0);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      if (filterSearch !== null) getAttackList(1, paginationModel.pageSize)
    }, 500)
    return () => clearTimeout(timer)
  }, [filterSearch])

  useEffect(() => {
    getAttackList(paginationModel.page + 1, paginationModel.pageSize);
  }, [paginationModel]);

  const getAttackList = async (page, limit) => {
    setshowLoader(true);
    const endpoint = process.env.REACT_APP_GET_ALL_ATTACK_LIST
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}?page=${page}&limit=${limit}&search=${filterSearch || ""}`
        )
          .then((resVendors) => {
            let arrResponse = resVendors.data.data?.data;
            setRowCount(resVendors.data.data?.total || 0)
            setVendorsData(arrResponse || []);
            setshowLoader(false);
          })
          .catch((errVendor) => {
            enqueueSnackbar(errVendor.message, { variant: "error" });
            setshowLoader(false);
          });
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
      setshowLoader(false);
    }
  };

  const removeData = async (url) => {
    const deleteId = userRemoveId;

    setshowLoader(true);
    const refreshToken = await PostRequest(
      `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
      {},
      {},
      "refreshToken"
    );
    if (refreshToken) {
      const endpoint = process.env.REACT_APP_DELETE_ATTACK;
      const payload = {
        requestAttackUrl: deleteId
      }
      await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`,
        payload
      )
        .then((resVendors) => {
          enqueueSnackbar(resVendors.data.message, { variant: 'success' });
          setshowLoader(false);
          window.localStorage.removeItem('deleteUrl');
          handleClose();
          setTimeout(() => {
            window.location.reload(true)
          }, 1000)
        })
        .catch((errVendor) => {
          enqueueSnackbar(errVendor.message, { variant: 'error' });
          setshowLoader(false);
        })
    }


  };

  const ModalContent = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h2 className="send-assessments">Are you sure want to remove assets ?</h2>
      </Typography>
    );
  };
  // Menu component for the three-dot menu
  function RowMenu({ data, handleRemove, handleView }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
      event.stopPropagation();
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton onClick={handleClick} className='three-dots-btn'>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className='rowmenu'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleView} className='border-bot-line'> <img src={EyeMenu} /> View</MenuItem>
          {(roleId === 2) ?
            <MenuItem onClick={handleRemove}> <img src={Trash} /> Remove</MenuItem> : ''
          }
        </Menu >
      </>
    );
  }
  const filteredRows =
    (!vendorsData || !vendorsData.length) ? [] :
      vendorsData
        ?.map((val, i) => ({
          ...val,
          id: i + 1,
        })
        );

  const columns = [
    {
      field: "id",
      headerName: "#",
      flex: 1,
      className: "serial-number",
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          {console.log(params.value)}
          <span className="logo-image-vendor">
            <img className="attack-surface-list-logo" src={`${process.env.REACT_APP_GET_LOGO_BY_THIRD_API}/${params?.row?.target}`} alt="Img" onError={(e) => {
              e.target.onerror = null;
              e.target.src = defaultLogo;
            }} />
          </span>
          <span style={{ display: 'flex', gap: '6px' }}>
            {params.value}
          </span>
        </div>
      ),
    },
    {
      field: "score",
      headerName: "Score",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      renderCell: (params) => (
        <RowMenu
          handleView={() => { handleRowClick(params) }}
          handleRemove={() => { handleOpen(params?.row?.target, roleId === 2 ? "admin" : "user") }}
        />
      ),
    }
  ];

  const handleRowClick = (params) => {
    navigate(`../attack-detail/${params.row.name}`)
  };

  const onFilterChange = (value) => {
    let searchQuery = value.quickFilterValues[0] || ""
    setFilterSearch(searchQuery)
  }
  const handleSearch = (event) => {
    setFilterSearch(event.target.value)
  };

  return (
    <>
      <Helmet>
        <title> Vendors | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Attack Surface" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <Card className="full-coverdesign margin-questionnaire">
              <div className="cover-table">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    top: "4px",
                    left: "5px",

                  }}
                >
                  <FormControl
                    className="select-entries"
                    variant="outlined"
                    size="small"
                  >
                    <Select
                      value={paginationModel.pageSize}
                      onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                  <span className="entries-per">entries per page</span>{" "}
                </div>
                <div className="issues-tab-table" style={{ marginTop: "-35px" }}>
                  <Box
                    sx={{
                      width: "100%",
                      "& .MuiDataGrid-columnHeaders": {
                        minWidth: "100%",
                        overflowX: "hidden",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        minWidth: "100%",
                        overflowX: "auto",
                      },
                    }}
                  >
                    <TextField
                      variant="outlined"
                      placeholder="Search…"
                      value={filterSearch}
                      onChange={handleSearch}
                      className='searh-text-field topsearch-top-0'
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={SearchIcon} alt='icon' />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <DataGrid
                      loading={loader}
                      rows={filteredRows}
                      rowCount={rowCount}
                      columns={columns}
                      rowHeight={70}
                      paginationMode="server"
                      pageSizeOptions={[5, 10, 25, 50]}
                      onPaginationModelChange={handlePaginationModelChange}
                      paginationModel={paginationModel}
                      slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                      }}
                      filterMode="server"
                      onFilterModelChange={onFilterChange}
                      disableRowSelectionOnClick
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableColumnMenu
                      disableSelectionOnClick
                      autoHeight
                      className="datagrid-table"
                      sx={{
                        "& .MuiDataGrid-columnHeaders": {
                          minWidth: "100%", // Column headers take up full width
                        },
                        "& .MuiDataGrid-virtualScroller": {
                          minWidth: "100%", // Rows take up full width
                        },
                      }}
                      onRowClick={handleRowClick}
                    />
                  </Box>
                </div>
              </div>
            </Card>
            <ModalDialog
              handleClose={handleClose}
              open={openUser}
              onSubmit={removeData}
              contentData={ModalContent()}
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
