import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

// hooks
import useResponsive from '../hooks/useResponsive';
// components
// import Logo from '../components/logo';
// import Iconify from '../components/iconify';
// sections
import { ChangePasswordForm } from '../sections/auth/changePassword';
import './css/ChangePassword.css'

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ChangePasswordPage() {
  const location = useLocation()
  let forcePasswordGenerate = location?.state?.forcePasswordGenerate;
  const mdUp = useResponsive('up', 'md');
  return (
    <>
      <Helmet>
        <title> Change Password | Genesis Platform  </title>
      </Helmet>
      <Container maxWidth={false}>
        {/* <StyledContent>         */}
        {/* <h4 className='heading-main'>Change your password</h4> */}
        <div className="change-password">
          <ChangePasswordForm forcePasswordGenerate={forcePasswordGenerate} />
        </div>
        {/* </StyledContent> */}
      </Container>
    </>
  );
}
