import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet-async";
import {
  Typography, Button,
  Stack, Card, Container, Box, styled,
  MenuItem,
  Menu,
  IconButton,
  FormControl,
  Select,
  Grid,
  InputAdornment,
  TextField
} from '@mui/material';
import GetRequest from '../components/apiConnections/getRequest';
import PostRequest from '../components/apiConnections/postRequest';
import QuestionnairePreview from './QuestionnairePreview';
import './css/addQuestionnaire.css'

import DeleteQuestionnaire from '../components/modal/deleteQuestionnaire';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import UploadQuestionnaireSet from '../components/modal/uploadQuestionnaireSet';
import QuestionnaireLogo from '../assets/questionnaire-image.png';
import NewHeader from '../layouts/dashboard/header/NewHeader';
import EyeMenu from '../assets/eye-menu.svg';
import Trash from '../assets/trahs.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import UploadIcon from '../assets/upload-icon-new.svg';
import Add from '../assets/plus-vendor.svg';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../assets/searchico.svg';
import { CustomNoRowsOverlay } from './Assessment';
// Menu component for the three-dot menu
function RowMenu({ onViewClick, onDeleteClick }) {

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} className='three-dots-btn'>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className='rowmenu'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onViewClick} className='border-bot-line'> <img src={EyeMenu} /> View</MenuItem>
        <MenuItem onClick={onDeleteClick}> <img src={Trash} /> Remove</MenuItem>
      </Menu>
    </>
  );
}

function QuestionnaireImport() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [activeQuestionnaireSet, setActiveQuestionnaireSet] = useState({})
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [showDeleteQuestionnaireModal, setShowDeleteQuestionnaireModal] = useState(false)
  const [showQuestionnaireImportModal, setShowQuestionnaireImportModal] = useState(false)
  const [questionnaireSets, setQuestionnaireSets] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  const [userEmail, setuserEmail] = useState(localStorage.getItem("userEmail"));
  const [rows, setRows] = useState([])
  const [rowCount, setRowCount] = useState(0);
  const [title, setTitle] = useState([])
  const [columns, setColumns] = useState([
    { field: 'id', headerName: 'No.', editable: false, maxWidth: 80, flex: 1 },
    {
      field: 'set',
      headerName: 'Name',
      flex: 1.1,
      minWidth: 170,  // Ensure minimum width
      disableColumnMenu: true,
      renderCell: (params) => (

        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          {/* {console.log("params ", params)} */}
          {params.row?.logo ? <span className="logo-image-vendor"><img className="attack-surface-list-logo" src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_QUESTIONNAIRE_LOGO}/${params.row?.logo}`} onError={(e) => {
            e.target.onerror = null;
            e.target.src = QuestionnaireLogo;
          }} alt='logo' /></span>
            : <span className="logo-image-vendor"><img className="attack-surface-list-logo" src={QuestionnaireLogo} alt='logo' /></span>
          }
          <span className='dots-words-limit' style={{ display: 'flex', gap: '6px' }}>
            {params.value}
          </span>
        </div>
      ),
    },
    {
      field: 'description', headerName: 'Description',
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
    },
    { field: "totalCategories", headerName: "Total Categories", editable: false, minWidth: 150, flex: 1, headerAlign: 'center', align: 'center' },
    {
      field: 'menu1', headerName: '',
      flex: 0.5,
      align: "right",
      disableColumnMenu: true,
      renderCell: (params) => <RowMenu onViewClick={() => configureQuestionnaireSet(params.row)} onDeleteClick={() => configureDeleteQuestionnaireSet(params.row)} />
    },
  ])
  const [searchText, setSearchText] = useState('');
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [pageSize, setPageSize] = useState(5);

  // useEffect(() => {
  //   getQuestionnaireSet()
  // }, [])

  useEffect(() => {
    getQuestionnaireSet(paginationModel.page + 1, paginationModel.pageSize);
  }, [paginationModel])

  useEffect(() => {
    let time = setTimeout(() => {
      if (searchText !== null) getQuestionnaireSet(1, paginationModel.pageSize);
    }, 500)
    return () => clearTimeout(time)
  }, [searchText])


  const configureQuestionnaireSet = (set) => {
    let arr = []
    set.setCategories?.forEach((data, i) => {
      let obj = {}
      obj.title = data.category;
      obj.questions = data.questions
      arr = [...arr, obj]
    })
    setTitle(set?.set)
    setActiveQuestionnaireSet({ ...set, sections: arr })
    setShowPreviewModal(true)
  }
  const configureDeleteQuestionnaireSet = (set) => {
    setActiveQuestionnaireSet({ ...set })
    setShowDeleteQuestionnaireModal(true)
  }

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  const handleEditClick = () => {
    setShowPreviewModal(false);
  };

  const handleRowClick = (params) => {
    configureQuestionnaireSet(params.row)
  }

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  const handleAddQuestionnaireClick = () => {
    navigate('./add-questionnaire');
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);

    const filtered = rows.filter((row) =>
      Object.values(row).some((field) =>
        String(field).toLowerCase().includes(value)
      )
    );

    // setFilteredRows(filtered);
  };

  const getQuestionnaireSet = async (page, limit) => {
    setShowLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ADMIN_CUSTOM_QUESTIONNAIRE_SET}?page=${page}&limit=${limit}&search=${searchText || ""}`,
          {}
        )
          .then((resChart) => {
            let data = resChart.data.data.result;
            setRowCount(resChart.data?.data?.totalQuestionnaire || 0)
            setQuestionnaireSets([...data?.map((d) => ({ ...d, totalCategories: d.setCategories?.length || 0 }))])
            setRows([...data?.map((d, i) => ({ ...d, id: ((page - 1) * limit) + i + 1, totalCategories: d.setCategories?.length || 0 }))])
            setShowLoader(false);
          })
          .catch((errChart) => {
            enqueueSnackbar(errChart.message, { variant: 'error' });
            setShowLoader(false);
          })
      } else {
        setShowLoader(false);
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setShowLoader(false);
    }
  }

  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Questionnaire library" />
        <Grid container spacing={3} sx={{ mb: 3 }} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <Card className="full-coverdesign margin-questionnaire">
              <div className="cover-table">
                {/* <QuestionnaireTable/> */}
                {/*New Table Static*/}
                <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '6px', left: '8px' }}>
                  <FormControl className='select-entries' variant="outlined" size="small">
                    <Select
                      value={paginationModel.pageSize}
                      onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                  <span className='entries-per'>entries per page</span>
                </div>
                <div className='question-buttons right-side-questionnaire-library'>
                  <Button
                    sx={{ marginRight: "0px" }}
                    variant="contained"
                    className="add-questionnaire-button widthfitupload"
                    onClick={() => setShowQuestionnaireImportModal(true)}
                  >
                    <img src={UploadIcon} alt='icon' style={{ marginRight: "13px" }} />  Upload Questionnaire
                  </Button>
                  <TextField
                    variant="outlined"
                    placeholder="Search…"
                    value={searchText}
                    onChange={handleSearch}
                    className='searh-text-field'
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={SearchIcon} alt='icon' />
                        </InputAdornment>
                      ),
                    }}
                  />

                </div>
                <div className='searh-text' style={{ marginTop: "200px" }}></div>
                <Box
                  sx={{
                    width: '100%',
                    '& .MuiDataGrid-columnHeaders': {
                      minWidth: '100%',
                      overflowX: 'hidden',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      minWidth: '100%',
                      overflowX: 'auto',
                    },
                  }}
                >

                  <DataGrid
                    loading={showLoader}
                    rows={rows} // Ensure you're using the filtered rows
                    rowCount={rowCount}
                    columns={columns}
                    pageSize={pageSize}
                    rowHeight={70}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    onPaginationModelChange={handlePaginationModelChange}
                    paginationModel={paginationModel}
                    paginationMode="server"
                    disableSelectionOnClick
                    autoHeight
                    className='datagrid-table'
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    pageSizeOptions={[5, 10, 25, 50]}
                    sx={{
                      '& .MuiDataGrid-columnHeaders': {
                        minWidth: '100%', // Column headers take up full width
                      },
                      '& .MuiDataGrid-virtualScroller': {
                        minWidth: '100%', // Rows take up full width
                      },
                    }}
                    onRowClick={handleRowClick}
                  />
                </Box>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {showPreviewModal ? <QuestionnairePreview
        open={showPreviewModal}
        handleClose={() => setShowPreviewModal(false)}
        onClose={handleEditClick}
        sections={activeQuestionnaireSet.sections}
        description={activeQuestionnaireSet.description}
        showSubmit={false}
        title={title}
      /> : null}
      {showDeleteQuestionnaireModal ? <DeleteQuestionnaire
        open={showDeleteQuestionnaireModal}
        handleClose={() => setShowDeleteQuestionnaireModal(false)}
        onClose={() => setShowDeleteQuestionnaireModal(false)}
        setId={activeQuestionnaireSet.setId}
        handleSuccess={() => getQuestionnaireSet(paginationModel.page + 1, paginationModel.pageSize)}
      /> : null}
      {showQuestionnaireImportModal ? <UploadQuestionnaireSet
        open={showQuestionnaireImportModal}
        handleClose={() => setShowQuestionnaireImportModal(false)}
        onClose={() => setShowQuestionnaireImportModal(false)}
        handleSuccess={() => { setShowQuestionnaireImportModal(false); getQuestionnaireSet(1, paginationModel.pageSize); }}
      /> : null}
    </>
  )
}

export default QuestionnaireImport