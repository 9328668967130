import * as React from "react";
import Drawer from "@mui/material/Drawer";
import redirectIcon from "../assets/redirect-icon.svg";
import Cross from "../assets/cross-select.svg";
import {
  Box,Button,Modal,
} from "@mui/material";
import { Margin } from "@mui/icons-material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,

};

function MappedIssuePopup({
  handleClose,
  open,
  issueData
}
) {
  // console.log("issueData" , issueData)
  const DrawerList = (
    <>
    {/* <Box
      sx={{ width: 800 , right: "30%"  }}
      className="drawer-padding"
      role="presentation"
    >
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Name</div>
        </div>
        <p>{issueData?.Issue}</p>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Description</div>
        </div>
        <p>{issueData?.Description}</p>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Remediation</div>
        </div>
        <p>{issueData?.Remediation}</p>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>References</div>
        </div>
        {issueData?.Reference?.map((link, index) => (
          <p key={index} style={{ display: 'flex' }}>
            #{index + 1}.{"    "}
            <a href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: '30px', display: 'flex', color: "white", flexDirection: 'row', flexWrap: 'wrap',width:"100%"  }}>
              <div style={{ marginLeft: '30px', display: 'flex', color: "white" ,marginRight: '1%', wordBreak:'break-all'}}>
                <div>{link} <img src={redirectIcon} alt='icon' style={{marginLeft:'8px',position:'relative', top:'7px'}}></img></div>
              </div>
             
            </a>

          </p>
        ))}
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Affected URls  </div>
        </div>
        {issueData?.URL?.map((link, index) => (
          <p key={index} style={{ display: 'flex' }}>
            #{index + 1}.{"    "}
            <a href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: '30px', display: 'flex', color: "white", flexDirection: 'row', flexWrap: 'wrap',width:"100%" }}>
              <div style={{ marginLeft: '30px', display: 'flex', color: "white",marginRight:'1%' }}>
                {link}
              </div>
              <img src={redirectIcon} alt='icon'></img>
            </a>
          </p>
        ))}
      </div>
      </Box> */}
      
      <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      >
        <Box sx={style} className="popupmapped-issues">
          <div class="pop-flex-remiadation">
            <h3>Mapped Issue Details</h3>
            <img src={Cross} alt="icon" style={{cursor:'pointer'}} onClick={handleClose}/>
          </div>
       <div className="cve-detail">
        <div className="cve-header-common">
          <div>Name</div>
        </div>
        <p>{issueData?.Issue}</p>
          </div>
          <div className="cve-detail">
        <div className="cve-header-common">
          <div>Description</div>
        </div>
        <p>{issueData?.Description}</p>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Remediation</div>
        </div>
        <p>{issueData?.Remediation}</p>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>References</div>
        </div>
        {issueData?.Reference?.map((link, index) => (
          <p key={index} style={{ display: 'flex' }}>
            #{index + 1}.{"    "}
            <a href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: '30px', display: 'flex', color: "white", flexDirection: 'row', flexWrap: 'wrap',width:"100%"  }}>
              <div style={{ marginLeft: '30px', display: 'flex', color: "white" ,marginRight: '1%', wordBreak:'break-all'}}>
                <div>{link} <img src={redirectIcon} alt='icon' style={{marginLeft:'8px',position:'relative', top:'7px'}}></img></div>
              </div>
             
            </a>

          </p>
        ))}
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Affected URLs  </div>
        </div>
        {issueData?.URL?.map((link, index) => (
          <p key={index} style={{ display: 'flex' }}>
            #{index + 1}.{"    "}
            <a href={link}
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: '30px', display: 'flex', color: "white", flexDirection: 'row', flexWrap: 'wrap',width:"100%" }}>
              <div style={{ marginLeft: '30px', display: 'flex', color: "white",marginRight:'1%' }}>
                {link}
              </div>
              <img src={redirectIcon} alt='icon'></img>
            </a>
          </p>
        ))}
      </div>
      </Box>
    </Modal>
    </>
  );

  return (
    <div>
      {/* <Drawer anchor="right" open={open} onClose={handleClose}
        ModalProps={{
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        }}> */}
        <></>
        {DrawerList}
      {/* </Drawer> */}
    </div>
  );
}

export default MappedIssuePopup;
