import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useState, useEffect } from "react";
import CrossSelect from '../../assets/cross-select.svg';
import Delete from "../../assets/delete.svg";
import { Grid, Typography, Button, CircularProgress, FormControl, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

export default function HardDeleteModel({
    handleClose,
    open,
    contentData,
    onSubmit,
    loader,
    activeVendor,
    text,
    type
}) {
    const [theme, setTheme] = useState(localStorage.getItem("theme"));
    const [confirmationText, setConfirmationText] = useState("")
    const style = {
        position: "absolute",
        top: "45%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "#120A3A",
        boxShadow: 24,
        textAlign: "center",
        borderRadius: "15px",
        p: 4,
        height: "470px !important"
        // overflowY: "auto"
    };
    useEffect(() => {
        if (open) {
            setConfirmationText(""); // Reset state when modal opens
        }
    }, [open]);
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={
                    theme === "Dark" ? "dark-modal modal-popup" : "light-modal modal-popup"
                }
            >
                <Box sx={style} className="select-vendor-popup" >
                    <div className='select-assessment-heading'>
                        <Typography>
                            Delete Ecosystem
                        </Typography>
                        <img src={CrossSelect} onClick={handleClose} alt='icon' />
                    </div>
                    <div className='padding-form-assessment topspace-extend' style={{ marginTop: "0px", }}>
                        <Typography id="modal-modal-description" style={{ textAlign: "center" }}>
                            <img src={Delete} alt="icon" />
                            <div className="" >
                                Are you sure you want to remove this {type === "vendor" ? "ecosystem" : type === "client" ? "client" : type === "clientUser" ? "user" : null}?<br />
                                {text} <br />
                                To confirm, please enter {type === "vendor" ? "domain" : type === "client" ? "email" : type === "clientUser" ? "email" : null} "<b>{activeVendor}</b>" below:
                            </div>
                        </Typography>
                        <Box sx={{ marginTop: 3, }}>
                            <FormControl fullWidth>
                                <TextField
                                    name="confirmationText"
                                    label={type === "vendor" ? "Domain" : type === "client" ? "Email" : type === "clientUser" ? "Email" : null}
                                    value={confirmationText}
                                    onChange={(e) => setConfirmationText(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    className=''
                                /></FormControl>
                        </Box>
                    </div>

                    <Grid item xs={12} md={12} lg={12} className="center-vendor" sx={{ margin: "20px  0px !important" }}>
                        <Button
                            variant="contained"
                            className="cancel-popups-btn cancel-as-well"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <LoadingButton loading={loader}
                            loadingIndicator={<CircularProgress size={12} sx={{ marginRight: "85px", color: "white" }} />}
                            size="large"
                            type="submit"
                            onClick={() => onSubmit(confirmationText)}
                            variant="contained" className="cancel-popups-btn"
                        >
                            <span>Confirm</span>
                        </LoadingButton>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
