import { useSnackbar } from 'notistack';
// @mui
import { LoadingButton } from "@mui/lab";
import {
  Box, CircularProgress, Grid, IconButton,
  InputAdornment, Link,
  Stack, TextField
} from "@mui/material";
// components
import PostRequest from "../../../components/apiConnections/postRequest";

// ----------------------------------------------------------------------
import { setLocalStoarge } from "../../../components/common/allFunctions";
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { GetPermissionList } from '../../../utils/getPermissionList';
import { useDispatch, useSelector } from "react-redux";
import { addPermissionList } from '../../../redux/permissionListSlice';
export default function LoginForm({ toggleTheme }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [theme, setTheme] = useState(localStorage.getItem('theme') || "Dark");
  const [showLoader, setshowLoader] = useState(false);
  const navigate = useNavigate();
  let selector = useSelector((state) => state.permissionList.permissionList);

  const checkPermission = (value) => {
    console.log("Value:", value)
    if (!localStorage.getItem("icu") || localStorage.getItem("icu") == "0") {
      return true;
    }
    else if (selector.includes(value)) return true;
    else return false;
  }
  const do_Login = async (e) => {
    e.preventDefault();
    setshowLoader(true);

    try {
      const { email, password } = inputValues;
      let Emailregex = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
      if (
        email === "" ||
        password === "" ||
        email === undefined ||
        password === undefined
      ) {
        enqueueSnackbar('Email Id or Password required.', { variant: 'error' });
        setshowLoader(false);
        return false;
      }

      if (!email.match(Emailregex)) {
        enqueueSnackbar('Email Id not valid.', { variant: 'error' });
        setshowLoader(false);
        return false;
      }
      const payload = {
        username: email,
        password: password,
      };

      const loginResult = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGIN_ENDPOINT}`,
        payload
      );

      if (loginResult && loginResult.status === 200) {
        if (loginResult.data.code === 200) {
          console.log(loginResult.data.data, "loginResult.data.data")
          if (loginResult.data.data.twofa == 0) {
            // twofa not enable
            const { accessToken, userTime, refreshToken, twofa, roleId, profilePic, userStatus, active, userId, isClientUser, companyDetails } = loginResult.data.data;
            const logged_user = {
              accessToken,
              refreshToken,
              twofa,
              profilePic,
            };
            setLocalStoarge("usertime", userTime);
            setLocalStoarge("roleId", roleId);
            setLocalStoarge("userEmail", email);
            setLocalStoarge("userId", userId);
            setLocalStoarge("c_detail", JSON.stringify(companyDetails))
            if (roleId === 1) {
              setLocalStoarge("R1", JSON.stringify(logged_user));

              if (isClientUser) {
                setLocalStoarge("icu", isClientUser)
              } else {
                setLocalStoarge("icu", 0)
                //IF you want to get Vendors Json data after login then uncomment the below code 
                // dispatch(fetchVendorsJson());
              }
            } else if (roleId === 2) {
              setLocalStoarge("R2", JSON.stringify(logged_user));
              setLocalStoarge("icu", 0)
            } else if (roleId === 3) {
              setLocalStoarge("R3", JSON.stringify(logged_user));
              setLocalStoarge("icu", 0)
            } else if (roleId === 4) {
              setLocalStoarge("R4", JSON.stringify(logged_user));
              setLocalStoarge("icu", 0)
            }
            enqueueSnackbar('Logged in successfully.', { variant: 'success' });
            setshowLoader(false);
            setTimeout(async () => {
              if (roleId === 1) {
                // navigate("/dashboard/app")
                if (isClientUser) {
                  let permissionList = await GetPermissionList()
                  if (!permissionList.error) dispatch(addPermissionList(permissionList.data))
                  {
                    permissionList.data?.includes("Dashboard") ? window.location.href = "/dashboard/app"
                      : permissionList.data?.includes("Ecosystem") ? window.location.href = "/dashboard/vendors"
                        : permissionList.data?.includes("Attack Surface") ? window.location.href = "/dashboard/attacks"
                          : permissionList.data?.includes("Questionnaire") ? window.location.href = "/dashboard/questionnaire-library"
                            : permissionList.data?.includes("Assessment") ? window.location.href = "/dashboard/assessment"
                              : permissionList.data?.includes("Vulnerability") ? window.location.href = "/dashboard/vulnerability"
                                : permissionList.data?.includes("Chat") && navigate("/dashboard/clientChat");
                  }
                } else window.location.href = "/dashboard/app"

              } else if (roleId === 2) {
                window.location.href = "/admin/addfile";
              } else if (roleId === 3) {
                if (active === 1) {
                  window.location.href = "/vendor/vendorAssessment";
                }
                else {
                  window.location.href = "vendor/change-password";
                }
              } else if (roleId === 4) {
                window.location.href = "third-party/dashboard";
              }
            }, 1000);
          } else {
            //two fa enabled,
            setLocalStoarge("userEmail", email);
            enqueueSnackbar(loginResult.data.message, { variant: 'success' });
            setshowLoader(false);
            navigate(`/twofa`)
            // window.location.href = "/twoFa";
          }
        }
      }
      else {
        console.log("Error 1234")
        enqueueSnackbar(loginResult.data.message, { variant: 'error' });

        // enqueueSnackbar('Unauthorized username or password.', { variant: 'error' });
        setshowLoader(false);
      }
    }
    catch (error) {
      console.log("Error 123", error);
      enqueueSnackbar('Unauthorized username or password.', { variant: 'error' });
      setshowLoader(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  return (
    <>
      {/* <Loader show={showLoader} /> */}

      <form onSubmit={(e) => do_Login(e)} className="relative">
        <Stack spacing={3}>
          <div className="inputfield">
            <TextField
              label='Email'
              name="email"
              placeholder="Email address"
              onChange={handleChange}
            />
          </div>
          <div className="inputfield">
            <TextField
              name="password"
              label="Password"
              placeholder="Password"
              className="inputfield"
              type={showPassword ? "text" : "password"}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className="eye-icon">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <Icon icon="mdi:eye-outline" style={{ width: 20, height: 18 }} /> : <Icon icon="solar:eye-closed-bold" style={{ width: 20, height: 18 }} />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </Stack>
        <LoadingButton className='login-buttton-gradient' loading={showLoader} loadingIndicator={<CircularProgress size={12} sx={{ marginRight: "70px", color: "white" }} />} fullWidth size="large" type="submit" variant="contained" >
          <span>Login</span>
        </LoadingButton>
        <Box>
          <Grid className="flex-direction" container sx={{ my: 2 }} >
            <Grid item xs className='class-right'>
              <Link href="/forget-password" className="forgot-text" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
        {/* <FormControlLabel className="dark-toggle" control={<Switch checked={theme === "Dark"} onChange={() => toggleModeButtonClick()} />} label={`${theme} Mode `} /> */}
      </form>
    </>
  );
}
