import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import {
    Grid, CircularProgress, Typography, FormControl, OutlinedInput,
    MenuItem, Checkbox, ListItemText, Select, InputLabel, TextField, Autocomplete, Chip

} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import UserProfile from '../../assets/user-profile.svg';
import EmailIcon from "../../assets/email-icon.svg";
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from "notistack";
import GetRequest from "../../components/apiConnections/getRequest";
import PostRequest from "../../components/apiConnections/postRequest";

export default function ClientListModal({
    handleClose,
    open,
    handleSuccess
}) {
    const { enqueueSnackbar } = useSnackbar();
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "Dark");
    const [message, setMessage] = useState("")
    const [clientList, setClientList] = useState([])
    const [selectedClient, setSelectedClient] = useState([])
    const [priority, setPriority] = useState("")
    const [showLoader, setShowLoader] = useState(false)
    const [searchText, setSearchText] = useState("");
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 600,
        bgcolor: "#120a3a",
        boxShadow: 24,
        textAlign: "center",
        borderRadius: "15px",
        // p: 4,

    };
    useEffect(() => {
        getClientList()
    }, []);

    const getClientList = async () => {
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                await GetRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL
                    }${process.env.REACT_APP_GET_CLIENT_LIST}`,
                    {}
                )
                    .then((resChart) => {
                        let data = resChart.data.data?.data;
                        setClientList(
                            data.length
                                ? [
                                    ...data?.map((d, i) => ({
                                        ...d,
                                        id: d._id,
                                        companyName: d.company,
                                        companyAdmin: `${d?.firstName} ${d.lastName}`,
                                        email: d.emailId,
                                        profilePic: d.profilePic
                                    })),
                                ]
                                : []
                        );
                    })
                    .catch((errChart) => {
                        console.log("errChart", errChart);
                        enqueueSnackbar(errChart.message || errChart.data?.message, { variant: "error" });
                    });
            } else {
                enqueueSnackbar("something went wrong.", { variant: "error" });
            }
        } catch (error) {
            console.log("error", error);
            enqueueSnackbar(error.message, { variant: "error" });
        }
    }
    const handleChange = (e) => {
        // console.log(e.target, "dddd")
        const { value, name } = e.target;
        if (name === "message") setMessage(value)
        else if (name === "priority") setPriority(value)
        else if (name === "clients") {
            if (value.includes("all")) {
                setSelectedClient(selectedClient?.length === clientList?.length ? [] : clientList?.map(item => item._id))
            } else {
                setSelectedClient(typeof value === 'string' ? value.split(',') : value)
            }
        }
    }

    const cleanData = () => {
        setMessage("")
        setPriority("");
        setSelectedClient([])
    }

    const handleSubmit = async () => {
        if (!selectedClient?.length) {
            enqueueSnackbar("Please select clients", { variant: "warning" })
            return;
        }
        if (!priority) {
            enqueueSnackbar("Please provide severity level", { variant: "warning" })
            return;
        }
        if (!message || message?.length < 3) {
            enqueueSnackbar("Please write appropriate message", { variant: "warning" });
            return;
        }
        setShowLoader(true)
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                await PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_NOTIFICATION_TO_CLIENT}`,
                    {
                        message,
                        priority,
                        clientIds: selectedClient
                    }
                )
                    .then((resChart) => {
                        enqueueSnackbar(resChart.data?.message, { variant: "success" });
                        setShowLoader(false);
                        cleanData()
                        handleSuccess()
                    })
                    .catch((errChart) => {
                        enqueueSnackbar(errChart.message, { variant: "error" });
                        setShowLoader(false);
                    });
            } else {
                setShowLoader(false);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: "error" });
            setShowLoader(false);
        }
    }

    const filteredClients = clientList?.filter(client =>
        `${client?.firstName} ${client?.lastName} ${client?.company}`.toLowerCase().includes(searchText?.toLowerCase())
    );
    return (
        <div>
            <Modal
                open={open}
                onClose={() => { cleanData(); handleClose() }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className={
                    theme === "Dark" ? "dark-modal modal-popup bgmodalpop" : "light-modal modal-popup"
                }
            >
                <Box sx={style}>
                    <Box sx={{ p: 4 }}>
                        <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", fontWeight: 500, fontSize: "20px", cursor: "pointer", textAlign: "left" }}>Send Notification  <CloseIcon onClick={() => { cleanData(); handleClose() }} /></Box>
                        <Box sx={{ marginTop: 3 }}>
                            <Autocomplete
                                multiple
                                options={[{ _id: "all", firstName: "Select All", lastName: "", company: "" }, ...clientList]}
                                getOptionLabel={(option) => `${option?.firstName} ${option?.lastName} (${option?.company})`}
                                value={clientList.filter(client => selectedClient.includes(client._id))}
                                onChange={(event, newValue) => {
                                    let clients = newValue.map(client => client._id)
                                    if (clients.includes("all")) {
                                        setSelectedClient(selectedClient?.length === clientList?.length ? [] : clientList?.map(item => item._id))
                                    } else {
                                        setSelectedClient(clients);
                                    }
                                }}
                                disableCloseOnSelect
                                renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Clients" placeholder="Search..." className="client-selection"

                                    />
                                )}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>

                                        {option._id == "all" ? <Checkbox checked={selectedClient?.length === clientList?.length} /> : <Checkbox checked={selected} />}
                                        {option._id == "all" ? <> {option.firstName} </> :
                                            <>{option.firstName} {option.lastName} ({option.company})</>}
                                    </li>
                                )}
                                renderTags={(selected, getTagProps) =>
                                    selected.map((option, index) => (
                                        <Chip key={option._id} label={`${option.firstName} ${option.lastName}`} {...getTagProps({ index })} />
                                    ))
                                }
                            />

                        </Box>
                        <Box sx={{ marginTop: 3 }}>
                            <FormControl  >
                                <InputLabel id="demo-select-small-label" required>Severity</InputLabel>
                                <Select
                                    required={true}
                                    sx={{ width: "100%" }}
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={priority}
                                    label="Severity"
                                    name="priority"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="" disabled>Select Severity</MenuItem>
                                    {["Info", "High", "Medium", "Low"].map((item) => (
                                        <MenuItem value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{ marginTop: 3, }}>
                            <FormControl fullWidth>
                                <TextField
                                    name="message"
                                    label="Message"
                                    value={message}
                                    onChange={(e) => handleChange(e)}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    className=''
                                /></FormControl>
                        </Box>
                        <Box sx={{ mt: 3, textAlign: "right" }}>
                            <Button
                                disabled={showLoader}
                                variant="contained"
                                className="edit-myaccount-button"
                                onClick={() => handleSubmit()}
                            >
                                Send
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
