import React, { useState, useEffect } from 'react';
import HeaderIcon from '../assets/search-icons.svg';
import GetRequest from '../components/apiConnections/getRequest';
import PostRequest from '../components/apiConnections/postRequest';
import './css/globalSearchBar.css';
import { useNavigate } from 'react-router-dom';
import GlobalSearchData from './GlobalSearchData';
import AILoader from '../AILoader';

export default function GlobalSearchBar({ isFullPage, searchInputData, onSearchChange }) {
    const [modalOpen, setModalOpen] = useState(null);
    const [showLoader, setShowLoader] = useState(false)
    const [search, setSearch] = useState('');
    const [answer, setAnswer] = useState("")
    const navigate = useNavigate();

    const globalFileSearchAPI = async (que) => {
        try {
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                setShowLoader(true)
                const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GLOBAL_FILE_SEARCH}?question=${que || search}`;

                GetRequest(url, {})
                    .then((response) => {
                        if (response.data.data) {
                            const answer = response?.data.data?.answer || "no data found"
                            setAnswer(answer)
                        } else setAnswer("")
                        setShowLoader(false)
                    })
                    .catch((error) => {
                        const fallbackSuggestions = [{
                            matchedSnippet: "Ask to Genesis AI Assistant"
                        }];                      
                        setShowLoader(false)
                        setAnswer("")
                    });
            }
        } catch (err) {
            console.log(err)
            setShowLoader(false)
            setAnswer("")
        }
    }
   
    const cleanText = (text) => {
        return typeof text === "string" ? text?.replace(/[^a-zA-Z0-9,. ]/g, '') : "no data";
    };

    useEffect(() => {
        setSearch(searchInputData)
        if (searchInputData?.length) {
            globalFileSearchAPI(searchInputData)
        }
    }, [searchInputData]);

    const handleSearchInputChange = (event) => {
        const inputValue = event.target.value;
        setSearch(inputValue);
    };

    const handleIconClick = () => {
        if (search?.length) navigate(`/dashboard/global-full-search-input?search=${search}`);
    }
    const cssValue = isFullPage ? "suggestions-list" : "bar-suggestions-list";
    return (
        <>
            <AILoader show={showLoader} />
            <div className='relative-search sparkle-search-icon'>
                <div className='max-inputai-data'>
                    <input
                        className={`input-header-search ${(search?.length > 0) ? 'input-header-search-active' : ''}`}
                        type='text'
                        placeholder='Search'
                        value={search}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") handleIconClick()
                        }}
                        onChange={handleSearchInputChange}

                    />
                    {isFullPage ? (
                        <img onClick={handleIconClick} src={HeaderIcon} className='topdetail-searchbar' alt='icon' />
                    ) : null}
                </div>

                {!isFullPage ? (
                    <img onClick={handleIconClick} src={HeaderIcon} alt='icon' />
                ) : null}
            </div >
            <div className='peragraphai-data'><p>{cleanText(answer)}</p></div>
            {modalOpen && <GlobalSearchData
                data={modalOpen}
            />}
        </>
    );
}
