import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  TextField, InputAdornment,
  Container,
  Card,
  Box,
  FormControl,Grid,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PostRequest from "../../components/apiConnections/postRequest.js";
import { useSnackbar } from "notistack";
import moment from "moment";
import "../../pages/css/Vendors.css";
import "../../pages/css/newVendor.css";
import NewHeader from "../../layouts/dashboard/header/NewHeader.js";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "../Assessment.js"
import SearchIcon from '../../assets/searchico.svg';

// ----------------------------------------------------------------------

export default function VendorAssessmentsPage() {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar();
  const [showLoader, setshowLoader] = useState(false);
  const [complianceData, setComplianceData] = useState({});
  const userEmail = window.localStorage.getItem("userEmail");
  // const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const [filterSearch, setFilterSearch] = useState(null)
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  // useEffect(() => {
  //   getAssessmentByVendor();
  // }, []);

  useEffect(() => {
    getAssessmentByVendor(paginationModel.page + 1, paginationModel.pageSize);
  }, [paginationModel]);

  useEffect(() => {
    let time = setTimeout(() => {
      if (filterSearch !== null) getAssessmentByVendor(1, paginationModel.pageSize)
    }, 500)
    return () => clearTimeout(time)
  }, [filterSearch])

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  const handleRowClick = (params) => {
    navigate(`../questionnaireSubmitted/${params.row._id}`, { state: { Data: params.row, panel: 'vendor' } })
  };
  const getAssessmentByVendor = async (page, pageSize) => {
    setshowLoader(true);
    try {
      const assessmentPayload = {
        email: userEmail,
      };
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ASSESSMENT_BY_VENDOR}?page=${page
          }&pageSize=${pageSize}&search=${filterSearch || ""}`,
          assessmentPayload
        )
          .then((resAssessment) => {
            // const obj = resAssessment?.data?.data?.data;
            setComplianceData(resAssessment?.data?.data?.data);
            setRowCount(resAssessment?.data?.data?.totalAssessment || 0);
            setshowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Assessment not find", { variant: "error" });
            setshowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      enqueueSnackbar("Requested Url not find", { variant: "error" });
      setshowLoader(false);
      return false;
    }
  };
  const filteredRows =
    (!complianceData || !complianceData.length) ? [] :
      complianceData
        ?.map((val, i) => ({
          ...val,
          id: i + 1 + (paginationModel?.page*paginationModel?.pageSize),
        }));
  const columns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.5,
      className: "serial-number",
      disableColumnMenu: true,
    },
    {
      field: "set",
      headerName: "Set Name",
      flex: 1.5,
      disableColumnMenu: true,
    },
    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1.1,
      disableColumnMenu: true,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: (params) => {
        return moment.unix(params.value).format("DD/MM/YYYY");
      }
    },
    {
      field: "deadline",
      headerName: "Deadline",
      flex: 1,
      disableColumnMenu: true,
      valueFormatter: (params) => {
        return moment.unix(params.value).format("DD/MM/YYYY");
      }
    },
    {
      field: "score",
      headerName: "Score",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <p style={{ margin: 0 }}>{params.row.score}/100</p>
        </div>
      ),
      // renderCell: (params) => {
      //   console.log("score graph:",params.row);
      //   let {totalAnswereGiven, totalAnswerePass, totalQuestionsInAssessment} = params.row;
      //   if(!totalQuestionsInAssessment || totalQuestionsInAssessment===0 ) return <>-</>
      //   const score = (totalAnswerePass/totalQuestionsInAssessment) * 100 
      //   let color = '';
      //   if(score <= 25){
      //     color = colors.RED;
      //   }else if(25 < score &&  score<= 50)
      //   {
      //     color = colors.ORANGE;
      //   }else if(50 < score && score<= 75)
      //   {
      //     color = colors.YELLOW;
      //   }else{
      //     color = colors.GREEN;
      //   }

      //   let chartInfo = {
      //     fillPercentage : parseInt(score),
      //     color : color
      //   };
      //   return <ComplianceBarChart fillPercentage {...chartInfo}/>
      //   // return <> { Math.trunc(score)} /100 </> 
      // },
    },
    {
      field: "firstName",
      headerName: "Created By",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "assessmentStatus",
      headerName: "Status",
      flex: 0.9,
      disableColumnMenu: true,
      headerAlign: "center",
      align:"right",
      renderCell: (params) => {
        let status = params.value;
        return (status !== "Extended Deadline" ?
          <span className={`statusClass ${status}`}>{status}</span>
          :
          <span className={`statusClass Extended_Deadline`}>{status}</span>
        );
      },
    },

  ];

  const handleSearch = (event) => {
    setFilterSearch(event.target.value)
  };

  return (
    <>
      <Helmet>
        <title> Dashboard | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Assessment" />
        <Grid container spacing={3} className="ipad-space">
         <Grid item xs={12} md={12} lg={12}>
           <Card className="full-coverdesign margin-questionnaire">
          <div className="cover-table">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                top: "4px",
                left: "5px",

              }}
            >
              <FormControl
                className="select-entries"
                variant="outlined"
                size="small"
              >
                <Select
                  value={paginationModel.pageSize}
                  onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <span className="entries-per">entries per page</span>{" "}
            </div>
            <div className="top-0issues">
              <Box
                sx={{
                  width: "100%",
                  "& .MuiDataGrid-columnHeaders": {
                    minWidth: "100%",
                    overflowX: "hidden",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    minWidth: "100%",
                    overflowX: "auto",
                  },
                }}
              >

                <TextField
                  variant="outlined"
                  placeholder="Search…"
                  value={filterSearch}
                  onChange={handleSearch}
                  className='searh-text-field topsearch-top-0'
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={SearchIcon} alt='icon' />
                      </InputAdornment>
                    ),
                  }}
                />

                <DataGrid
                  loading={showLoader}
                  rows={filteredRows}
                  columns={columns}
                  rowHeight={70}
                  onPaginationModelChange={handlePaginationModelChange}
                  paginationModel={paginationModel}
                  rowCount={rowCount}
                  components={{
                    NoRowsOverlay: () => (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          textAlign: "center",
                          fontSize: "18px",
                        }}
                      >
                        No data
                      </Box>
                    ),
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                    filter: {
                      filterModel: {
                        items: [],
                        quickFilterValues: [],
                      },
                    },
                  }}
                  autosizeOptions={{
                    columns: ["set", "firstName"],
                    includeOutliers: true,
                    includeHeaders: false,
                  }}
                  pageSizeOptions={[5, 10, 25, 50]}
                  slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  disableRowSelectionOnClick
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  disableColumnMenu
                  paginationMode="server"
                  disableSelectionOnClick
                  autoHeight
                  className="datagrid-table click-hover-datagrid"
                  sx={{
                    "& .MuiDataGrid-columnHeaders": {
                      minWidth: "100%", // Column headers take up full width
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      minWidth: "100%", // Rows take up full width
                    },
                  }}
                  onRowClick={handleRowClick}
                />
              </Box>
            </div>
          </div>
           </Card>
          </Grid>
        </Grid>
          

        {/* {showLoader === true ? (
          <Loader show={showLoader} />
        ) : complianceData.length > 0 ? (
          <Box
            sx={{ width: "100%", typography: "body1" }}
            className="ipad-space"
          >
                
                {complianceData.length > 0 ? (
                  <div className="Assessment-box">
                    {complianceData.map((key, val) => (
                      
                      <>                  
                        <Grid
                          container
                          columns={0}
                          columnSpacing={3}
                          className="box-Assessment"
                        >
                          <Grid item xs={12} md={2} lg={1}>
                            <div className="widthboxes">
                             <h5>Set</h5>
                              <p>{key.set}</p>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={2} lg={2}>
                            <div className="widthboxes">
                              <h5>Start Date</h5>
                              <p>
                                {moment(new Date(key.startDate * 1000)).format(
                                  "MMMM Do YYYY"
                                )}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={2} lg={2}>
                            <div className="widthboxes">
                              <h5>Deadline</h5>
                              <p>
                                {moment(new Date(key.deadline * 1000)).format(
                                  "MMMM Do YYYY"
                                )}
                              </p>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={2} lg={1.5}>
                            <div className="widthboxes">
                              <h5>Progress</h5>
                              <p>{key.score}/100</p>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={2} lg={1.5}>
                            <div className="widthboxes">
                              <h5>Created By</h5>
                              <p>{key.firstName}</p>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={2} lg={2}>
                            <div className="widthboxes">
                              <h5>Status</h5>
                              <p>{key.assessmentStatus}</p>
                            </div>
                          </Grid>
                          

                          <Grid item xs={12} md={2} lg={1.2}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                              <Link to={`../questionnaireSubmitted/${key._id}`}>
                                <Button
                                  variant="contained"
                                  className="addnew send-assessment"
                                  style={{ minWidth: '80px', maxWidth: '100px' }} // Added maxWidth and minWidth
                                >
                                  Show
                                </Button>
                              </Link>
                            </div>
                          </Grid>
                      
                        </Grid>
                      </>
                    ))}
                  </div>
                ) : (
                  <div className="Assessment-box">
                    <div className="manage-mid">
                      <h2>No Assessment Found!</h2>                     
                    </div>
                  </div>
                )}          
          </Box>) : (
          <Box sx={{ display: "flex" }}>
            <Grid>
              <Card item xs={12} md={6} lg={6} sx={{ p: 5 }}>
                <h4>No Assessment Found</h4>
              </Card>
            </Grid>
          </Box>
        )} */}
      </Container>
    </>
  );
}
