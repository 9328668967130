import * as React from "react";
import Drawer from "@mui/material/Drawer";
import redirectIcon from "../../assets/redirect-icon.svg";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";
import CriticalImg from "../../assets/critical-image.svg";
import LowImage from '../../assets/low-img.svg';
import MediumImage from '../../assets/medium-img.svg';
import HighImage from '../../assets/high-img.svg';


function IssueSideBar({
  handleClose,
  open,
  issueData
}
) {
  console.log(issueData)
  const seveirtyIssue = () => {
    let image;
    const severity = issueData?.severity
    if (severity === 'low')
      image = LowImage
    if (severity === 'medium')
      image = MediumImage
    if (severity === 'critical')
      image = CriticalImg
    else if (severity === 'high')
      image = HighImage;  // You need to define this image or handle it
    return (<span style={{ display: "flex" }}>
      {image ? <img src={image} alt='icon'></img> : "-"}
    </span>)
  }
  const DrawerList = (
    <Box
      sx={{ width: 800 }}
      className="drawer-padding"
      role="presentation"
    >
      <div className="cve-detail">
        <TableContainer sx={{ p: 1 }} className="Drawer-popup-rightside">
          <Table
            sx={{ minWidth: 100 }}
            aria-label="simple table"
            className="table-attacks"
          >
            <TableHead sx={{ borderRadius: "10px 10px 0 0" }}>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Severity</TableCell>
                {issueData?.category === "breaches" ? <TableCell>Data</TableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{issueData?.name}</TableCell>
                <TableCell>{seveirtyIssue()}</TableCell>
                {issueData?.category === "breaches" ? <TableCell>{issueData?.ele?.Data || "-"}</TableCell> : null}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Description</div>
        </div>
        <p>{issueData?.ele?.Description}</p>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>Remediation</div>
        </div>
        <p>{issueData?.ele?.Remediation}</p>
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>References</div>
        </div>
        {issueData?.ele?.Reference?.map((link, index) => (
          <p key={index} style={{ display: 'flex' }}>
            #{index + 1}.{"    "}
            <a href={link.startsWith("http://") || link.startsWith("https://")
              ? link : `http://${link}`
            }
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: '30px', display: 'flex', color: "white", flexDirection: 'row', flexWrap: 'wrap', width: "100%" }}>
              <div style={{ marginLeft: '30px', display: 'flex', color: "white", marginRight: '1%', wordBreak: 'break-all' }}>
                <div>{link} <img src={redirectIcon} alt='icon' style={{ marginLeft: '8px', position: 'relative', top: '7px' }}></img></div>
              </div>

            </a>

          </p>
        ))}
      </div>
      <div className="cve-detail">
        <div className="cve-header-common">
          <div>{issueData?.category === "breaches" ? "Sources" : "Affected URLs"}   </div>
        </div>
        {Array.isArray(issueData?.urls) && issueData?.urls?.map((link, index) => (
          <p key={index} style={{ display: 'flex' }}>
            #{index + 1}.{"    "}
            <a href={link.startsWith("http://") || link.startsWith("https://")
              ? link : `http://${link}`
            }
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: '30px', display: 'flex', color: "white", flexDirection: 'row', flexWrap: 'wrap', width: "100%" }}>
              <div style={{ marginLeft: '30px', display: 'flex', color: "white", marginRight: '1%' }}>
                {link}
              </div>
              <img src={redirectIcon} alt='icon'></img>
            </a>
          </p>
        ))}
        {/* {Array.isArray(issueData?.ele?.Source) && issueData?.ele?.Source?.map((link, index) => (
          <p key={index} style={{ display: 'flex' }}>
            #{index + 1}.{"    "}
            <a href={link.startsWith("http://") || link.startsWith("https://")
              ? link : `http://${link}`
            }
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: '30px', display: 'flex', color: "white", flexDirection: 'row', flexWrap: 'wrap', width: "100%" }}>
              <div style={{ marginLeft: '30px', display: 'flex', color: "white", marginRight: '1%' }}>
                {link}
              </div>
              <img src={redirectIcon} alt='icon'></img>
            </a>
          </p>
        ))} */}
      </div>
    </Box>
  );

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleClose}
        ModalProps={{
          "disablebackdropclick": "true",
          "disableEscapeKeyDown": "true",
        }}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

export default IssueSideBar;
