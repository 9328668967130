import { Box, Card, Container, FormControl, Grid, InputLabel, MenuItem, Select, Tab, Tabs } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { CustomNoRowsOverlay } from "../Assessment.js";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import ClientRemediationTable from "./ClientRemediationTable.js";
import PostRequest from "../../components/apiConnections/postRequest.js";
function ClientRemediation() {
    const data = useParams();
    const [tabValue, setTabValue] = useState(0);
    const userEmail = window.localStorage.getItem("userEmail");
    const [vendorUrl, setVendorUrl] = useState(data.url);
    const [showLoader, setShowLoader] = useState(true);
    const [remediationData, setRemediationData] = useState([]);
    const handleMainTabChange = (event, newValue) => {
        if (newValue === 2) {
            setSelectedType("ForGaps")
        }
        if (newValue === 1) {
            setSelectedType("ForQuestionnaire")
        }
        if (newValue === 0) {
            setSelectedType("ForIssue")
        }
        setTabValue(newValue);
    };
    const [selectedType, setSelectedType] = useState("ForIssue");

    useEffect(() => {
        getRemediation();
    }, []);

    const getRemediation = async () => {
        setShowLoader(true);
        try {
            var rgx = /^[a-z|0-9]*\.?[a-z|0-9]*$/;
            const remediationPayload = {
                createdBy: userEmail,
                url: vendorUrl,
            };
            const refreshToken = await PostRequest(
                `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
                {},
                {},
                "refreshToken"
            );
            if (refreshToken) {
                PostRequest(
                    `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_REMEDIATION_PLAN_BY_USER}`,
                    remediationPayload
                )
                    .then((resRemediation) => {
                        const obj2 = resRemediation.data.data;
                        setRemediationData(obj2.reverse());
                        setShowLoader(false);
                    })
                    .catch((err) => {
                        // no data
                        // enqueueSnackbar("Remediation not find", { variant: "error" });
                        setShowLoader(false);
                        return false;
                    });
            }
        } catch (error) {
            // no data
            // enqueueSnackbar("Remediation not find", { variant: "error" });
            setShowLoader(false);
            return false;
        }
    };

    const filteredRows =
        ((!remediationData || !remediationData?.length) ? [] :
            remediationData
                .filter((val, i) =>
                    !selectedType || val.remediationType.toLowerCase() === selectedType.toLowerCase()
                )
                ?.map((val, i) => ({
                    ...val,
                    id: i + 1,
                    _id: val.id,
                    name: `${val?.firstName} ${val?.lastName}`,
                    uniqueId: val.uniqueId || "--"
                }))
        );
    return (
        <>

            <Container maxWidth={false} className="max-w-left">
                <Grid container spacing={3} className="ipad-space">
                    <Grid item xs={12} md={12} lg={12}>
                        <div className="first_tab">
                            <Tabs
                                value={tabValue}
                                onChange={handleMainTabChange}
                                className="tab-main-heading margin-mui-tabfix"
                                aria-label="nested tabs example"
                            >
                                <Tab className="active-color-remove second-mui" label="Issue" />
                                <Tab className="active-color-remove second-mui" label="Failed Control" />
                                <Tab className="active-color-remove second-mui" label="Gaps" />
                            </Tabs>

                            <div>
                                <ClientRemediationTable
                                    remediationListData={filteredRows}
                                    type={selectedType}
                                    loader={showLoader}
                                />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container >
        </>
    );
}

export default ClientRemediation;