import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  Select,
  MenuItem,
  IconButton,
  Menu,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DataGrid } from "@mui/x-data-grid";
import EyeMenu from "../../assets/eye-menu.svg";
import Minus from "../../assets/icon-minus.svg";
import Plus from "../../assets/icon-plus.svg";
import { useNavigate } from 'react-router-dom';
import { CustomNoRowsOverlay } from "../Assessment.js";
import PostRequest from "../../components/apiConnections/postRequest.js";
import GetRequest from "../../components/apiConnections/getRequest.js";

// Menu component for the three-dot menu
function RowMenu({ data, handleRemove, handleView }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} className="three-dots-btn">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="rowmenu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleView} className="border-bot-line">
          {" "}
          <img src={EyeMenu} /> View
        </MenuItem>
      </Menu>
    </>
  );
}

function VulnerabilityManagementIssue() {
  const [activeTab1, setActiveTab1] = useState();
  const [activeTab2, setActiveTab2] = useState();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false)
  const [vendorNameList, setVendorNameList] = useState([]);
  const [issuesNameList, setIssuesNameList] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [apiLoader, setApiLoader] = useState(false)
  const [issuesData, setIssuesData] = useState([]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  // Handle Vendor Checkbox Change
  const handleVendorChange = (vendor) => {
    setSelectedVendors((prevSelected) =>
      prevSelected.includes(vendor)
        ? prevSelected.filter((item) => item !== vendor) // Remove if already selected
        : [...prevSelected, vendor] // Add if not selected
    );
  };

  // Handle Issue Checkbox Change
  const handleIssueChange = (issue) => {
    setSelectedIssues((prevSelected) =>
      prevSelected.includes(issue)
        ? prevSelected.filter((item) => item !== issue) // Remove if already selected
        : [...prevSelected, issue] // Add if not selected
    );
  };

  const columns = [
    { field: "_id", headerName: "#", flex: 0.5, disableColumnMenu: true },
    {
      field: "name",
      headerName: "Name",
      flex: 1.5,
      minwidth: 200,
      disableColumnMenu: true,
    },
    {
      field: "count",
      headerName: "Affected Vendors",
      flex: 1.2,
      minwidth: 150,
      disableColumnMenu: true,
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        let scoreTag = "-";
        const severity = String(params.row.severity).replace(/<[^>]*>/g, "")
          .replaceAll('"', "")
          .trim()
          .toLowerCase()

        if (severity === 'low')
          scoreTag = "Low";
        if (severity === 'medium')
          scoreTag = "Medium";
        if (severity === 'critical')
          scoreTag = "Critical";
        else if (severity === 'high')
          scoreTag = "High";

        return (
          scoreTag ? (
            <span className={`tagClassapproval blank-white ${scoreTag}`}>
              <span>{scoreTag}</span>
            </span>
          ) : (
            "-"
          )
        );
      },
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minwidth: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        const formatCategory = (value) => {
          if (value?.toLowerCase() === "dns") {
            return "DNS"; // Make "DNS" fully capitalized
          }
          return value.replace(/\b\w/g, (char) => char.toUpperCase());
        };
        return formatCategory(params.value);
      },
    },
    {
      field: "action",
      headerName: "",
      flex: 1,
      minwidth: 150,
      align: "right",
      disableColumnMenu: true,
      renderCell: (params) => (
        <div>
          <RowMenu
            handleView={() => { handleRowClick(params) }}
          />
        </div>
      ),
    },
  ];

  const filteredRows =
    (!issuesData || !issuesData.length
      ? []
      : issuesData
        .filter((iss) => iss?.issueId != null && iss?.issueId != null) // Corrected null check
        .filter((issue) => {
          const matchesVendor =
            !selectedVendors || selectedVendors.length === 0
              ? true
              : issue.domains?.some((domainObj) =>
                selectedVendors.includes(domainObj.domain.split('.')[0])
              );
          return matchesVendor;
        })
        .filter((issue) => {
          const matchesIssue =
            !selectedIssues || selectedIssues.length === 0
              ? true
              : selectedIssues.includes(issue.issueName);
          return matchesIssue;
        })
        .map((val, i) => ({
          ...val,
          _id: i + 1,
          name: val.issueName,
          count: val.affectedDomains,
          severity: val.severity,
          category: val.category,
        }))
    );

  const handleRowClick = (params) => {
    navigate("/dashboard/vulnerability-detail", { state: { issueData: params?.row } });
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };

  useEffect(() => {
    getAllVendor();
  }, [])

  useEffect(() => {
    const issuesRows = new Set();
    const vendorListSet = new Set();
    console.log("issuesData", issuesData);
    issuesData.map(vendor => {
      const vendorInfo = vendor?.domains;
      vendorInfo?.map(item => {
        vendorListSet.add(item?.domain?.split('.')[0])
      })
      if (vendor?.issueName && vendor?.issueName?.toLowerCase() !== "null") issuesRows.add(vendor?.issueName)
    })
    const issuesList = Array.from(issuesRows)
    const vendorList = Array.from(vendorListSet);
    setVendorNameList(vendorList);
    setIssuesNameList(issuesList);
  }, [issuesData])

  const getAllVendor = async () => {
    setApiLoader(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_ISSUES_FOR_VULNERABILITY}`,
        )
          .then((res) => {
            const data = res?.data?.data.filter((item) => item?.issueName !== null && item?.issueName !== "null")
            setIssuesData(data);
            setApiLoader(false);
          })
          .catch((err) => {
            setApiLoader(false);
          })
      }
    }
    catch (error) {
      setApiLoader(false);
    }
  }

  return (
    <div className="filter-divide-two">
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} lg={9} style={{ paddingTop: "0px" }}>
          <div className="cover-table">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <FormControl
                className="select-entries"
                variant="outlined"
                size="small"
              >
                <Select
                  value={paginationModel.pageSize}
                  onChange={(e) =>
                    handlePaginationModelChange({
                      page: 0,
                      pageSize: e.target.value,
                    })
                  }
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                </Select>
              </FormControl>
              <span className="entries-per">entries per page</span>
            </div>
            <DataGrid
              rows={filteredRows}
              columns={columns}
              getRowId={(row) => row._id} // Use the custom ID for each row
              loading={apiLoader || showLoader}
              rowHeight={70}
              style={{ cursor: "pointer" }}
              onRowClick={handleRowClick}
              paginationMode="client"
              pageSizeOptions={[5, 10, 25]}
              onPaginationModelChange={handlePaginationModelChange}
              paginationModel={paginationModel}
              autoHeight
              className='datagrid-table'
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={3} lg={3} style={{ paddingTop: "0px" }}>
          <div className="filter-right">
            <h3>Filters</h3>
            <div className="accordion-item">
              <h4
                onClick={() => setActiveTab1(!activeTab1)}
                className="accordion-header"
              >
                Vendor
                <span className="accordion-icon">
                  <img
                    src={activeTab1 ? Minus : Plus}
                    alt="toggle-icon"
                    style={{ cursor: "pointer", marginLeft: 8 }}
                  />
                </span>
              </h4>
              {activeTab1 && (
                <div className="accordion-content filter">
                  <div className="bg-backgrounds autoscroll" style={{ "maxHeight": "135px" }}>
                    {vendorNameList?.map((vendor) => (
                      <div className="inline-form-check checkbox-outline" key={vendor}>
                        <label className="custom-checkbox">
                          <input
                            type="checkbox"
                            id={`flexCheckDefault-${vendor}`}
                            checked={selectedVendors?.includes(vendor)}
                            onChange={() => handleVendorChange(vendor)}
                          />
                          <span className="checkmark"></span>
                          <span className="assessment-datacheck">{vendor?.replace(/\b\w/g, (char) => char.toUpperCase())}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>

            <div className="accordion-item">
              <h4
                onClick={() => setActiveTab2(!activeTab2)}
                className="accordion-header"
              >
                Issue Name
                <span className="accordion-icon">
                  <img
                    src={activeTab2 ? Minus : Plus}
                    alt="toggle-icon"
                    style={{ cursor: "pointer", marginLeft: 8 }}
                  />
                </span>
              </h4>
              {activeTab2 && (
                <div className="accordion-content filter">
                  <div className="bg-backgrounds autoscroll" style={{ "maxHeight": "150px" }}>
                    {issuesNameList?.map((issue) => (
                      <div className="inline-form-check checkbox-outline" key={issue}>
                        <label className="custom-checkbox">
                          <input
                            type="checkbox"
                            id={`flexCheckDefault-${issue}`}
                            checked={selectedIssues?.includes(issue)}
                            onChange={() => handleIssueChange(issue)}
                          />
                          <span className="checkmark"></span>
                          <span className="assessment-datacheck">{issue?.replace(/\b\w/g, (char) => char.toUpperCase())}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}

            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default VulnerabilityManagementIssue;
