import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  List,
  ListItemButton,
  Menu,
  MenuItem,
  Popover,
  FormControl,
  Card,
  Select,
  Typography, InputAdornment, TextField, Grid,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Iconify from "../components/iconify";
import { StyledGridOverlay } from "./Assessment";
import PostRequest from "../components/apiConnections/postRequest";
import QuestionnaireLogo from "../assets/companyLogo.jpg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@emotion/react";
import "./css/clientsManagement.css";
import Delete from "../assets/delete.svg";
import ModalDialog from "./ModalDialog";
import GetRequest from "../components/apiConnections/getRequest";
import DeleteRequest from "../components/apiConnections/deleteRequest";
import NewHeader from "../layouts/dashboard/header/NewHeader";
import SearchIcon from '../assets/searchico.svg';
import EyeMenu from '../assets/eye-menu.svg';
import Trash from '../assets/trahs.svg';
import EditIcon from '../assets/edit-icon-2.svg';
import HardDeleteModel from "./models/hardDeleteModel";
export function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data Found </Box>
    </StyledGridOverlay >
  );
}
function DataGridLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
        overflow: "hidden",
      }}
    >
      <CircularProgress />
    </Box>
  );
}

const ClientsManagementPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [showLoader, setShowLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([
    { field: "id", headerName: "No.", editable: false, flex: 1, maxWidth: 80 },
    {
      field: "logos",
      headerName: "Logo",
      maxWidth: 80,
      flex: 1,
      sortable: false,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => {
        return (
          <Box className=" icons-aligns-new icons-aligns  add-questionnaire-action mui-icons-questionnaire">
            <img
              src={
                params.row?.profilePic
                  ? `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_STATIC_PATH}${params.row?.profilePic}`
                  : QuestionnaireLogo
              }
              alt="logo"
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                if (!params.row.clientDetail?.websites?.length) return;
                if (params.row.clientDetail?.websites?.length === 1) {
                  let item = params.row.clientDetail?.websites[0];
                  const hasProtocol =
                    item.startsWith("http://") || item.startsWith("https://");
                  const url = hasProtocol ? item : `${window.location.protocol}//${item}`;
                  window.open(url, "_blank");
                } else {
                  handleLogoClick(event, params.row)
                }
              }}
              onError={(e) => e.target.src = QuestionnaireLogo}
            />
          </Box>
        );
      },
    },
    {
      field: "companyName",
      headerName: "Company Name",
      editable: false,
      minWidth: 150,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "companyAdmin",
      headerName: "Company Admin",
      editable: false,
      minWidth: 120,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "contact",
      headerName: "Contact Number",
      editable: false,
      minWidth: 100,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email Address",
      editable: false,
      minWidth: 200,
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 20,
      sortable: false,
      headerAlign: "right",
      align: "right",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <IconButton
              aria-label="more"
              aria-controls="actions-menu"
              aria-haspopup="true"
              onClick={(event) => handleActionClick(event, params.row)}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const navigate = useNavigate();
  const [activeClient, setActiveClient] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [filterSearch, setFilterSearch] = useState(null)
  useEffect(() => {
    getClient(paginationModel.page + 1, paginationModel.pageSize);
  }, [paginationModel]);
  useEffect(() => {
    let time = setTimeout(() => {
      if (filterSearch !== null) getClient(1, paginationModel.pageSize)
    }, 500)
    return () => clearTimeout(time)
  }, [filterSearch])
  const handleLogoClick = (event, logo) => {
    if (logo?.clientDetail?.websites?.length) {
      setAnchorEl1(event.currentTarget);
      setSelectedLogo(logo);
    }
  };

  const handleClose = () => {
    setAnchorEl1(null);
    setSelectedLogo(null);
  };
  const handleActionClick = (event, row) => {
    event.stopPropagation();
    setSelectedRow(row);
    setAnchorEl2(event.currentTarget);
  };

  const handleActionClose = (event, row) => {
    event.stopPropagation();
    setSelectedRow(null);
    setAnchorEl1(null);
  };

  const getClient = async (page, limit) => {
    setShowLoader(true);
    setRows([])
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL
          }${process.env.REACT_APP_GET_CLIENT_LIST}?page=${page
          }&limit=${limit}&search=${filterSearch || ""}`,
          {}
        )
          .then((resChart) => {
            let data = resChart.data.data?.data;
            setRowCount(resChart.data.data?.total);
            setRows(
              data.length
                ? [
                  ...data?.map((d, i) => ({
                    ...d,
                    id: (page - 1) * limit + (i + 1),
                    companyName: d.company,
                    companyAdmin: `${d.firstName} ${d.lastName}`,
                    contact: d.mobile || "---",
                    email: d.emailId,
                  })),
                ]
                : []
            );
            setShowLoader(false);
          })
          .catch((errChart) => {
            console.log("errChart", errChart);
            enqueueSnackbar(errChart.message || errChart.data?.message, { variant: "error" });
            setShowLoader(false);
          });
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar(error.message, { variant: "error" });
      setShowLoader(false);
    }
  };

  const handleLinkClick = (item) => {
    const hasProtocol =
      item.startsWith("http://") || item.startsWith("https://");
    const url = hasProtocol ? item : `${window.location.protocol}//${item}`;
    window.open(url, "_blank");
  };

  const deleteClientAPI = async (confirmationText) => {
    if(confirmationText !== selectedRow?.emailId){
      enqueueSnackbar("Please write correct email", { variant: "error" });
      return;
    }
    setDeleteLoader(true);
    const payload = {
      clientId: selectedRow._id,
      confirmationText: confirmationText
    };
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await DeleteRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_DELETE_CLIENT}`,
          payload,
          {}
        )
          .then((resChart) => {
            setDeleteLoader(false);
            setShowDeleteModal(false);
            enqueueSnackbar(resChart.data?.message, { variant: "success" });
            setAnchorEl2(null);
            getClient(paginationModel.page + 1, paginationModel.pageSize);
          })
          .catch((errChart) => {
            console.log(errChart)
            enqueueSnackbar(errChart.data?.message, { variant: "error" });
            setDeleteLoader(false);
          });
      } else {
        setDeleteLoader(false);
      }
    } catch (error) {
      console.log(error)
      enqueueSnackbar(error.message, { variant: "error" });
      setDeleteLoader(false);
    }
  };

  const ModalContentDeleteClient = () => {
    return (
      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        <img src={Delete} alt="icon" />
        <h2 className="send-assessments">
          Are you sure you want to remove this client ?
        </h2>
      </Typography>
    );
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  const onFilterChange = (value) => {
    let searchQuery = value.quickFilterValues[0] || ""
    setFilterSearch(searchQuery)
  }
  const handleFilterSearch = (e) => {
    setFilterSearch(e.target.value)
  }
  const handleRowClick = (params) => {
    navigate(`../client-details/${params._id}`, {
      state: params,
    })
  }
  return (
    <>
      <Helmet>
        <title> Clients Management | Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
        <NewHeader titleName="Clients" />
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
            <div className="mbtop-manage">
              <Button
                variant="contained"
                className="edit-myaccount-button margin-right-send-assessment-button"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => navigate("../client/add")}
              >
                Add Client
              </Button>
            </div>
            <Card className="full-coverdesign margin-questionnaire">
              <div className="cover-table">
                <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', top: '6px', left: '8px' }}>
                  <FormControl className='select-entries' variant="outlined" size="small">
                    <Select
                      value={paginationModel.pageSize}
                      onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                    </Select>
                  </FormControl>
                  <span className='entries-per'>entries per page</span>
                </div>
                <Box
                  sx={{
                    width: '100%',
                    '& .MuiDataGrid-columnHeaders': {
                      minWidth: '100%',
                      overflowX: 'hidden',
                    },
                    '& .MuiDataGrid-virtualScroller': {
                      minWidth: '100%',
                      overflowX: 'auto',
                    },
                    pt: 3
                  }}
                >
                  <TextField
                    variant="outlined"
                    placeholder="Search…"
                    value={filterSearch}
                    onChange={handleFilterSearch}
                    className='searh-text-field topsearch-top-0'
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={SearchIcon} alt='icon' />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <DataGrid
                    rowHeight={70}
                    rows={rows}
                    columns={columns}
                    initialState={{}}
                    autosizeOptions={{
                      columns: ["id", "firstName", "lastName"],
                      includeOutliers: true,
                      includeHeaders: false,
                    }}
                    paginationMode="server"
                    pageSizeOptions={[5, 10, 25]}
                    onPaginationModelChange={handlePaginationModelChange}
                    paginationModel={paginationModel}
                    rowCount={rowCount}
                    slots={{
                      toolbar: GridToolbar,
                      noRowsOverlay: CustomNoRowsOverlay,
                      loadingOverlay: DataGridLoader,
                    }}
                    onRowClick={(params) => handleRowClick(params.row)}
                    loading={showLoader}
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    disableRowSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnMenu
                    autoHeight
                    className='datagrid-table'
                    sx={{
                      '& .MuiDataGrid-columnHeaders': {
                        minWidth: '100%', // Column headers take up full width
                      },
                      '& .MuiDataGrid-virtualScroller': {
                        minWidth: '100%', // Rows take up full width
                      },
                    }}
                  />
                  <Popover
                    id="logos-menu"
                    open={Boolean(anchorEl1)}
                    anchorEl={anchorEl1}
                    onClose={handleClose}
                    className='rowmenu'
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                  >
                    <Box p={0}>
                      {selectedLogo && (
                        <List>
                          {Array.isArray(selectedLogo?.clientDetail?.websites) &&
                            selectedLogo?.clientDetail.websites?.map((item) => (
                              <ListItemButton onClick={() => handleLinkClick(item)}>
                                {item}
                              </ListItemButton>
                            ))}
                        </List>
                      )}
                    </Box>
                  </Popover>
                  {selectedRow && (
                    <Menu
                      id="actions-menu"
                      anchorEl={anchorEl2}
                      open={Boolean(anchorEl2)}
                      onClose={handleActionClose}
                      className='rowmenu'
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <MenuItem onClick={() =>
                        navigate(`../client-details/${selectedRow._id}`, {
                          state: selectedRow,
                        })
                      }
                        className={
                          theme.palette.mode === "dark"
                            ? "dark-menu-item-btn"
                            : "light-menu-item-btn"
                        }
                      >
                        <img src={EyeMenu} /> View
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          navigate(`../client/edit/${selectedRow._id}`, {
                            state: activeClient,
                          })
                        }
                        className={
                          theme.palette.mode === "dark"
                            ? "dark-menu-item-btn"
                            : "light-menu-item-btn"
                        }
                      >
                        <img src={EditIcon} /> Edit
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setActiveClient(selectedRow.id);
                          setShowDeleteModal(true);
                          setAnchorEl2(null);
                        }}
                      >
                        <img src={Trash} /> Remove
                      </MenuItem>
                    </Menu>
                  )}
                </Box>
                {/* </Box> */}
              </div>
            </Card>
          </Grid>
        </Grid>
      </Container>

      {/* <ModalDialog
        handleClose={() => setShowDeleteModal(false)}
        open={showDeleteModal}
        loader={deleteLoader}
        onSubmit={() => deleteClientAPI()}
        contentData={ModalContentDeleteClient()}
      /> */}

      <HardDeleteModel
        handleClose={() => { setShowDeleteModal(false); setSelectedRow(null) }}
        open={showDeleteModal}
        onSubmit={deleteClientAPI}
        loader={deleteLoader}
        activeVendor={selectedRow?.emailId}
        text={"This action will permanently delete all client-related data, including users, assessments, remediations, attacks and questionnaires."}
        type="client"
      />
    </>
  );
};

export default ClientsManagementPage;
