import React, { useEffect, useState } from "react";
// @mui
import {
  Card,
  Box,
  CircularProgress,
  Stack,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Import your desired icon
import GetRequest from "../components/apiConnections/getRequest";

function BluredVerticalChartBarForIssue() {
  const [value, setValue] = useState("false");
  const [loader, setShowLoader] = useState(false);
  const [totalIssue, setTotalIssue] = useState(0);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const chartData = [
    {
      "issueName": {
        "Issue": "Employee Data Breach",
        "Severity": "null"
      },
      "issueCount": 106
    },
    {
      "issueName": {
        "Issue": "x-permitted-cross-domain-policies",
        "Severity": "null"
      },
      "issueCount": 17
    },
    {
      "issueName": {
        "Issue": "content-security-policy",
        "Severity": "null"
      },
      "issueCount": 17
    },
    {
      "issueName": {
        "Issue": "x-content-type-options",
        "Severity": "null"
      },
      "issueCount": 17
    },
    {
      "issueName": {
        "Issue": "x-frame-options",
        "Severity": "null"
      },
      "issueCount": 17
    },
    {
      "issueName": {
        "Issue": " Invalid SSL/TLS Subject ",
        "Severity": "null"
      },
      "issueCount": 16
    },
    {
      "issueName": {
        "Issue": "Null",
        "Severity": "null"
      },
      "issueCount": 2
    },
    {
      "issueName": {
        "Issue": "strict-transport-security",
        "Severity": "null"
      },
      "issueCount": 1
    }
  ]
  const colors = ['#2C226D', '#2C226D', '#2C226D', '#2C226D', '#2C226D'];
  const colors2 = ['#63A7E3', '#439B46', '#CA8B3B', '#BFA73E', '#7D4DD0']
  const colorsCritical = ['#2C226D', '#2C226D', '#2C226D', '#2C226D', '#2C226D'];
  const colorsCritical2 = ['#FF1B16', '#FFBC11', '#FF015E', '#DE22FF', '#FF5404']
  const i = 1;
  useEffect(() => {
    const totalIssueCount = chartData.reduce((total, item) => total + item.issueCount, 0);
    setTotalIssue(totalIssueCount);
  }, [chartData]);

  return (
    <>
      <Card sx={{ mb: 1 }} className="blur-box-cover-area vertical-bar-chart-blur">
        <div className="top-heading-dashboard ">
          <div className="filter-select-dropdown">
            <h2>Issue Vertical Bar Chart </h2>
          </div>
        </div>
        <p className="top-issues-vertical-heading"> {value === "false" ? "Top Issues" : "Top Critical Issues"}</p>
        {!loader ?
          <>
            {(chartData && chartData?.length > 0) ?
              (chartData?.slice(0, 5)
                .map((key, i) => (
                  <Stack
                    sx={{ width: "100%", mt: 2 }}
                    spacing={2}
                    className="vertical-chart-linearprocess"
                    key={i}
                  >

                    <div className="cover-vertical-chart">
                      <p className="linearprocess-peragraph">
                        {key?.issueName?.Issue}
                        {totalIssue && <span>{((Math.abs(Number(key?.issueCount)) * 100) / totalIssue)?.toFixed(2)}%</span>}
                      </p>
                      {value === "false" ? <LinearProgress
                        sx={{
                          backgroundColor: colors[i],
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: colors2[i],
                          },
                        }}
                        variant="determinate"
                        className="vertical-progresschart"
                        value={key?.issueCount}
                      /> :
                        <LinearProgress
                          sx={{
                            backgroundColor: colorsCritical[i],
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: colorsCritical2[i],
                            },
                          }}
                          variant="determinate"
                          className="vertical-progresschart"
                          value={key?.issueCount}
                        />
                      }
                    </div>
                  </Stack>
                ))) :
              // When no data is Available
              (
                <Stack
                  sx={{ width: "100%", mt: 2 }}
                  spacing={2}
                  className="vertical-chart-linearprocess"
                >

                  <div className="cover-vertical-chart">
                    <p className="linearprocess-peragraph">
                      No Data Available
                    </p>
                  </div>
                </Stack>
              )
            }
          </> :
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: "23%" }}>
            <CircularProgress className="loader-custom-class-new" />
          </Box>
        }

      </Card>
    </>
  );
}

export default BluredVerticalChartBarForIssue;
